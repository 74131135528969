.expert-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1200px;
}

.expert-page-content {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1234px;
    background: transparent;
    display: flex;
    justify-content: space-between;
}

.expert-banner {
    position: absolute;
    height: 180px;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    display: flex;
    align-items: end;
    background-image: url("../pic/expertBk3.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.expert-banner-img-one {
    /*position: absolute;*/
    height: 160px;
    /*max-width: 1800px;*/
    object-fit: cover;
    overflow: hidden;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.expert-banner-img-two {
    /*position: absolute;*/
    height: 160px;
    margin-left: calc(100% - 460px);
    /*max-width: 1800px;*/
    object-fit: cover;
    overflow: hidden;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.expert-ct-ad {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 340px;
    height: 1230px;
    background: transparent;
}

.expert-ct-ad-one {
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.expert-ct-ad-two {
    margin-top: 30px;
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.expert-data-item {
    width: 98%;
    height: 190px;
    background: #ffffff;
    margin: 20px 0 60px 0;
    display: flex;
    align-items: center;
}

.expert-item-pic {
    width: 160px;
    height: 160px;
    cursor: pointer;
    object-fit: cover;
}

.expert-item-square {
    height: 190px;
    width: 50px;
    background: white;
    display: flex;
    align-items: center;
}

.expert-item-pic-outer {
    height: 190px;
    width: 190px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-item-ct-outer {
    height: 190px;
    width: 56%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-item-ct {
    height: 160px;
    width: 96%;
    background: transparent;
    display: flex;
    flex-direction: column;
}

.expert-item-ct-line {
    height: 25%;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
}

.expert-item-ct-container {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px;
    letter-spacing: 2px;
    padding-left: 10px;
    background: transparent;
    align-items: center;
}

.expert-item-line-sp {
    height: 100%;
    width: 50%;
    display: flex;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 2px;
    align-items: center;
}

.expert-item-line-key {
    /*padding-right: 10px;*/
    width: 87px;
    white-space: nowrap;
    box-sizing: border-box;
}

.expert-item-name {
    font-weight: bold;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px;
    background: transparent;
    white-space: nowrap;
}

.expert-item-fun-outer {
    width: calc(44% - 240px);
    max-width: 320px;
    height: 50%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.expert-item-button {
    font-size: 12px;
    background: #ffffff;
    width: 98px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 1px;
    text-indent: 1px;
    color: rgb(95, 156, 229);
    border: 1px solid rgb(95, 156, 229);
    cursor: pointer;
    margin: 5px;
}

.expert-item-button:hover {
    background: rgb(95, 156, 229);
    color: rgb(255, 255, 255);
}

.expert-ct-data {
    min-height: 1234px;
    width: calc(100% - 340px);
    background: transparent;
}

/*.expert-banner-img {*/
/*    position: absolute;*/
/*    height: 180px;*/
/*    max-width: 1800px;*/
/*    object-fit: cover;*/
/*    overflow: hidden;*/
/*    width: 100%;*/
/*    z-index: 0;*/
/*    image-rendering: -moz-crisp-edges;*/
/*    image-rendering: -o-crisp-edges;*/
/*    image-rendering: -webkit-optimize-contrast;*/
/*    image-rendering: crisp-edges;*/
/*    -ms-interpolation-mode: nearest-neighbor;*/
/*}*/

.export-bk {
    width: 100%;
    height: 180px;
    display: flex;
    z-index: -1;
    justify-content: center;
}

.export-bk-o {
    position: absolute;
    width: 100%;
    height: 180px;
    display: flex;
}

.export-bk-l {
    width: 50%;
    height: 180px;
    background: #e4f9fe;
}

.export-bk-r {
    width: 50%;
    height: 180px;
    background: #d6ebfa;
}

.expert-filter {
    position: relative;
    width: 60%;
    min-width: 1150px;
    max-width: 1800px;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
}

.expert-filter-ct {
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 308px;
    background: transparent;
    /*border-radius: 6px;*/
    /*box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);*/
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.expert-filter-outer {
    width: 100%;
    min-height: 308px;
    background: rgba(228, 234, 239, 0.85);
    z-index: 3;
    /*border-radius: 6px;*/
    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly;*/
    align-items: center;
}

.script-header-avatar {
    position: relative;
    /*margin-right: 80px;*/
    width: 300px;
    height: 226px;
}

.expert-avatar-bg {
    z-index: 99;
    position: relative;
    width: 300px;
    height: 226px;
}

.expert-avatar {
    z-index: 10;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 180px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
}

.script-expert-name {
    margin: 0 0 10px 0;
    font-size: 32px;
    font-weight: bolder;
    line-height: 40px;
    letter-spacing: 2px;
    color: white;
    font-family: Apple, sans-serif;
}

.script-header-l-expert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 300px);
    height: 75%;
}

.expert-calendar-modal-ct {
    z-index: 1100;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1020px;
    height: 690px;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-cal-wrap {
    position: relative;
    /*padding: 30px 0;*/
    width: 100%;
    height: calc(100% - 100px);
    box-sizing: border-box;
    overflow-y: auto;
}

.modal-cal-ct {
    position: relative;
    margin: 0 auto;
    /*padding: 30px 0;*/
    /*padding-bottom: 50px;*/
    width: 100%;
    height: auto;
    /*min-height: 650px;*/
}

.appoint-date {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 50px;
}

.appoint-date-title {
    width: 100px;
}

.appoint-date-link {
    margin: 0 20px;
    width: auto;
    font-size: 16px;
}

.appoint-date-select {
    width: 100px;
    height: 60%;
    /*min-width: 70px;*/
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    padding: 3px 2px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.appoint-date-select:hover {
    border: 1px solid #8391a5;
}

.appoint-date-select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.appoint-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    width: auto;
}

.expert-header {
    /*background: #4b6cb7;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to left, #5e83bb 10%, #83abd5 90%);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to left, #5e83bb 10%, #83abd5 90%); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    background: #1e3c72;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #2a5298, #1e3c72);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #2a5298, #1e3c72); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ellipse {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}