.error-page {
    position: relative;
    width: 100%;
    height: auto;
    min-width: 1200px;
}

.btn-wrap {
    position: absolute;
    top: 90%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, 0);
}

.btn {
    display: inline-block;
    margin: 0 10px;
    width: 180px;
    height: 50px;
    border-radius: 30px;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    outline: 0;
    box-sizing: border-box;
    cursor: pointer;
}

.back {
    background: #2576d9;
    color: #fff;
}

.home {
    background: #fff;
    color: #2576d9;
}