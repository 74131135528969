.solution-banner-outer {
    height: 240px;
    min-width: 1200px;
    width: 100%;
    max-width: 2000px;
    /*background: grey;*/
    background-image: url("../pic/SLB.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
}

.solution-banner-img {
    height: 240px;
}

.solution-banner-container {
    height: 240px;
    min-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.solution-ct-outer {
    width: 100%;
    height: auto;
    min-width: 1200px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.solution-ct-inner {
    margin: 30px auto 75px auto;
    width: 80%;
    /*height: auto;*/
    min-width: 1200px;
    max-width: 1600px;
    min-height: 1200px;
    max-height: 3600px;
    background: white;
    display: flex;
}

.solution-inner-left {
    display: flex;
    flex-direction: column;
    width: 74%;
    height: auto;
    background: #ffffff;
}

.solution-ct-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    padding: 0 0 0 15px;
    height: 60px;
    width: calc((100% - 40px) * 0.96);
    border: 1px solid rgba(227, 227, 227, 0.8);
}

.solution-ct-menu-ele {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    width: auto;
    height: 70%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 4px;
    text-indent: 4px;
    color: #6d6d6d;
    cursor: pointer;
}

.solution-ct-menu-ele-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    width: auto;
    height: 70%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 4px;
    text-indent: 4px;
    color: black;
    border-bottom: 3px solid rgb(37, 118, 217);
    cursor: pointer;
}

.solution-ct-layout {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    margin: 40px 0 40px 20px;
    width: calc(100% - 40px);
    background: transparent;
    /*height: 100%;*/
}

.solution-inner-right {
    width: 26%;
    height: auto;
    background: white;
}

.solution-inner-right-inner {
    margin: 20px 0;
    width: calc(100% - 20px);
    height: auto;
    /*height: 2530px;*/
    background: #ffffff;
}

.solution-adv-img {
    height: 500px;
    width: 100%;
    background: #b6b6b6;
}

.solution-adv-img-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.solution-adv-ct {
    margin-top: 20px;
    width: calc(100% - 2px);
    height: auto;
    background: #ffffff;
    /*border: 1px solid #d5d5d5;*/
}


.solution-data-item {
    display: flex;
    margin: 0 14px 40px 0;
    width: 48%;
    height: 200px;
    background: white;
    border: 1px solid rgba(227, 227, 227, 0.8);

}

.solution-data-item-pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*object-fit: cover;*/
}

.solution-data-item-tag-layout {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    height: 100%;
    background: white;
    overflow: hidden;
}

.solution-data-item-tag-layout-img {
    height: 80%;
    width: 86%;
    overflow: hidden;
}

.solution-data-item-ct-layout {
    width: 60%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.solution-data-item-ct {
    padding: 0 0 0 20px;
    width: 90%;
    /*background: #6d6d6d;*/
    height: 80%;
}

.solution-data-item-title {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
    width: 100%;
    height: auto;
    font-size: 20px;
    font-weight: bold;
    /*line-height: 28px;*/
    color: #2a65ac;
    letter-spacing: 2px;
    cursor: pointer;
}

.solution-data-item-des {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: auto;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
    letter-spacing: 2px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.solution-data-item:hover {
    border: 1px solid rgb(168, 200, 236);
    box-shadow: 0 0 4px rgba(16, 58, 104, 0.3);
    color: #0066ff;
}

.solution-data-item:hover .solution-data-item-pic {
    transform: translateY(0%) scale(1.1);
    transition: all 400ms;
}

.solution-ct-ad {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: auto;
    width: 100%;
    background: transparent;
}

.solution-ct-ad-expert {
    width: 100%;
    border: 1px solid rgba(227, 227, 227, 0.8);
    margin-top: 30px;
    height: 720px;
    background: transparent;
}
