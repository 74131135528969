.outcome-page-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1134px;
    background: transparent;
}

.outcome-ct-data {
    min-height: 1134px;
    width: calc(100% - 340px);
    background: transparent;
}

.outcome-data-item {
    width: 98%;
    height: 230px;
    max-width: 1200px;
    background: transparent;
    margin: 10px 0 50px 0;
    display: flex;
}

.outcome-data-item-pic-outer {
    width: 340px;
    height: 200px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outcome-data-item-pic-inner {
    width: 290px;
    height: 186px;
    border-radius: 8px;
    overflow: hidden;
}

.outcome-item-pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition-property: transform;
    -moz-transition-property: transform; /* Firefox 4 */
    -webkit-transition-property:transform; /* Safari and Chrome */
    -o-transition-property:transform; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.outcome-item-square {
    height: 230px;
    width: 50px;
    background: white;
    display: flex;
    align-items: center;
}

.outcome-data-item-main {
    width: calc(100% - 50px);
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(227, 227, 227, 0.8);
    cursor: pointer;
    color: #3965b1;
    transition-property: transform, border, box-shadow;
    -moz-transition-property: transform, border, box-shadow; /* Firefox 4 */
    -webkit-transition-property:transform, border, box-shadow; /* Safari and Chrome */
    -o-transition-property:transform, border, box-shadow; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
    text-decoration: none;
}

.outcome-data-item-main:hover {
    border: 1px solid rgb(168, 200, 236);
    box-shadow: 0 0 4px rgba(16, 58, 104, 0.3);
    color: #0066ff;
    transform: translate3d(0, -6px, 0);
    text-decoration: none;
}

.outcome-data-item-ct-outer {
    width: calc(100% - 400px);
    height: 94%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: transparent;
    margin-left: 20px;
}

.outcome-data-item-title {
    height: 16%;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    -webkit-box-orient: vertical;
    white-space: nowrap;
}

.outcome-data-item-tags {
    height: 18%;
    width: 100%;
    display: flex;
    align-items: center;
}

.outcome-data-item-tag-title {
    font-size: 14px;
    padding-right: 10px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #535353;
}

.outcome-data-item-des {
    height: 74px;
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    margin-top: 6px;
}

.outcome-page-partition {
    width: 98%;
    height: 80px;
    background: transparent;
    margin: 10px 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.outcome-ct-ad {
    height: 1130px;
    width: 340px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.outcome-ct-ad-one {
    width: 100%;
    height: 500px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.outcome-ct-ad-two {
    margin-top: 30px;
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.outcome-page-partition-ct {
    width: 90%;
    height: 50px;
    background: white;
    display: flex;
    justify-content: center;
}

.outcome-bk {
    width: 100%;
    height: 400px;
    background: #124b90;
    display: flex;
    z-index: -1;
    justify-content: center;
}

.outcome-filter {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.outcome-filter-ct {
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 408px;
    background: transparent;
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    margin: -300px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.filter-outer {
    width: 100%;
    min-height: 408px;
    background: rgba(202, 228, 255, 0.8);
    z-index: 3;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly;*/
    align-items: center;
}

.filter-selected-tags {
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
}

.outcome-banner-img {
    position: absolute;
    height: 400px;
    /*max-width: 1800px;*/
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.outcome-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1200px;
}

.data-item-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 0;
    width: 60%;
    height: auto;
}

.data-item-empty-img {
    width: 200px;
    height: 200px;
}

.data-item-empty-txt {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;

}

.script-ct {
    margin-bottom: 50px;
    width: 100%;
    /*height: 2000px;*/
    min-width: 1200px;
}

.script-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
}

.outcome-header {
    background: #4b6cb7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.script-header-ct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-width: 1200px;
    max-width: 1800px;
}

.script-header-l {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    height: 75%;
}

.script-header-r {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 528px;
    height: 330px;
}

.script-img-bg {
    width: 528px;
    height: 330px;
}

.script-name {
    margin: 0 0 20px 0;
    width: 80%;
    font-size: 32px;
    font-weight: bolder;
    letter-spacing: 2px;
    /*font-family: Apple, sans-serif;*/
    /*color: #252525;*/
}

.outcome-name {
    /*background-image: linear-gradient(98deg, #08C1B5, #194CFF);*/
    /*color: transparent;*/
    /*-webkit-background-clip: text;*/
    color: white;
}


.script-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 35px;
    font-size: 16px;
    color: #ffffff;
    /*font-family: Apple, sans-serif;*/
}

.contact-bt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0;
    width: 180px;
    height: 50px;
    /*padding: 15px 20px;*/
    border-radius: 5px;
    cursor: pointer;
    background-image: url("../../assets/img/service_bg.jpg");
    /*background: -webkit-linear-gradient(to right,#06f,#00b3ff);*/
    /*background: -o-linear-gradient(to right,#06f,#00b3ff);*/
    /*background: -moz-linear-gradient(to right,#06f,#00b3ff);*/
    /*background: linear-gradient(to right,#06f,#00b3ff);*/
    /*font-family: Apple, sans-serif;*/
    box-shadow: 0 0 4px #000000;
}

.contact-bt:hover {
    /*font-weight: bold;*/
    transform: scale(1.02);
}

.contact-bt-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
}

.calendar-style {
    font-size: 18px;
    color: white;
}

.contact-bt-text {
    margin-left: 8px;
    font-size: 16px;
    color: #ffffff;
}

.script-img {
    position: absolute;
    width: 400px;
    height: 250px;
    /*border-radius: 20px;*/
    /*border: 2px solid white;*/
    overflow: hidden;
    /*box-shadow: 0 10px 20px rgba(255, 255, 255, 0.3);*/
    transform: translate(0, -9px);
}

.script-page-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 30px 0 0;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1134px;
    background: transparent;
}

.script-ct-data {
    width: calc(100% - 360px);
    min-height: 1134px;
    background: transparent;
}

.script-main {
    width: 100%;
    height: auto;
    /*background-color: #9fccff;*/
}

.script-main-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
}

.script-main-ct {
    margin: 30px 0 0;
    padding: 30px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 110px);
    border: 1px solid rgba(208, 208, 208, 0.5);
}

.script-item-ct {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    /*background-color: #a9a9a9;*/
}

.script-item-title {
    padding-left: 8px;
    width: auto;
    height: auto;
    border-left: 3px solid rgb(60, 128, 212);
    font-size: 20px;
    font-weight: bold;
    color: rgb(47, 47, 47);
}

.script-item-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px auto 0;
    width: 95%;
    height: auto;
    font-size: 16px;
    color: #5a5a5a;
}

.expert-info {
    flex-direction: column;
    align-items: flex-start
}

.script-item-info-left {
    width: calc(100% - 430px);
    height: auto;
}

.script-item-info-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 270px;
    border: 10px solid rgba(255,255,255,.1);
    box-shadow: 0 0 5px 3px rgb(0 0 0 / 10%);
    box-sizing: border-box;
    overflow: hidden;
    background-color: transparent;
}

.script-item-info-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5px;
    width: auto;
    height: auto;
    font-size: 16px;
    color: #000000;
}

.script-item-info-tag-title {
    width: 80px;
    height: 40px;
}

.script-item-info-tag-value {
    width: calc(100% - 80px);
    /*line-height: 23px;*/
    /*line-height: 30px;*/
}

.lock-part {
    margin: 20px auto 0;
    width: 95%;
    height: 300px;
    border: 1px solid #e4eef6;
}

.script-item-content {
    margin: 20px auto 0;
    width: 95%;
    height: auto;
    font-size: 16px;
    line-height: 35px;
    /*text-indent: 2em;*/
    letter-spacing: 1px;
    color: #5a5a5a;
    white-space: pre-wrap;
}

.script-com-schedule {
    margin: 20px auto 0;
    width: 95%;
    height: auto;
}

.service-process {
    margin: 20px auto 0;
    width: 95%;
    /*height: 150px;*/
    border-radius: 5px;
    overflow: hidden;
}

.service-process:hover {
    transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.safety-guarantee {
    margin: 20px auto 0;
    width: 95%;
    /*height: 300px;*/
    border-radius: 5px;
    /*background-color: #7f8893;*/
    overflow: hidden;
}

.safety-guarantee:hover {
    transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.contact-plugin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 20px 0 0 2.5%;
    width: 400px;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #eee;
    background: linear-gradient(to right,#06f,#00b3ff);
}

.contact-plugin:hover {
    font-weight: bold;
    transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.filter-blur {
    filter: blur(5px);
    user-select: none;
    cursor: pointer;
}

.display-none {
    display: none;
}

.get-vip-tip {
    margin-left: 10px;
    font-size: 12px;
    color: #128bed;
    cursor: pointer;
}

.get-vip-tip:hover {
    color: #007add;
}

.login-panel {
    z-index: 99;
    position: relative;
    margin: 0 auto;
    padding: 20px 20px;
    width: 400px;
    height: 120px;
    /*border: 1px solid #324bc9;*/
    border-radius: 5px;
    background-color: #fff;
    transform: translate(0, -235px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
}

.pay-panel {
    z-index: 99;
    position: relative;
    margin: 0 auto;
    padding: 20px 20px;
    width: 500px;
    height: 200px;
    /*border: 1px solid #020825;*/
    border-radius: 5px;
    background-color: #fff;
    transform: translate(0, -275px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
}

.pay-panel-title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 35px;
    color: #000000;
}

.rights {
    margin-left: 10px;
    font-size: 14px;
    color: #f9ad14;
    cursor: pointer;
}

.rights:hover {
    color: #f96414;
}

.pay-wrap {
    margin-bottom: 25px;
    padding: 20px 75px 10px;
    background: #fff;
    /*border: 1px solid #eee;*/
    /*border-radius: 6px;*/
}

.goods-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 70px;
}

.goods-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 148px;
    height: 70px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    cursor: pointer;
}

.price {
    line-height: 30px;
    font-size: 14px;
    color: #666;
}

.big-num {
    font-size: 18px;
    font-weight: bold;
}

.color-mark {
    color: #F9552A
}

.desc {
    font-size: 12px;
    color: #666;
}

.pay-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 130px;
    height: 40px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
    background: #ec4417;
    cursor: pointer;
}

.pay-btn:hover {
    background: #F9552A;
}

/* antd breadcrumb */
.ant-breadcrumb {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    color: #00000073;
    font-size: 16px;
}

.ant-breadcrumb-separator {
    margin: 0 8px;
    color: #00000073;
}

.ant-breadcrumb a {
    color: #00000073;
    transition: color .3s;
    text-decoration: none;
}

.ant-breadcrumb a:hover {
    color: #40a9ff;
}

.ant-breadcrumb ol {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}


/* swiper */
.swiper-main-ct {
    width: 100%;
    height: 78%;
}

.swiper-sub-ct {
    width: 100%;
    height: 20%;
    /*background-color: rgba(56, 56, 56, 0.3);*/
}

.swiper-sub-ct .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.swiper-sub-ct .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-sub-ct .swiper-wrapper {
    justify-content: center;
    background-color: rgba(239, 239, 239, 0.8);
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/
}
