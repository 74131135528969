body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, '宋体', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #bababa;
  font-size: 16px;
  letter-spacing: 4px;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #bababa;
  font-size: 16px;
  letter-spacing: 4px;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #bababa;
  font-size: 16px;
  letter-spacing: 4px;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #bababa;
  font-size: 16px;
  letter-spacing: 4px;
}

select {
  border: 1px solid red;
}
