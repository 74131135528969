.filter-header {
    width: 100%;
    height: 26%;
    background: transparent;
    display: flex;
}

.expert-filter-header {
    width: 100%;
    height: 60%;
    background: transparent;
    display: flex;
    align-items: center;
}

.policy-filter-header {
    width: 100%;
    height: 60%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.policy-filter-search {
    width: 88%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-search {
    width: 70%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.filter-search-expert {
    width: 60%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.message-win {
    padding: 8px 14px;
    width: auto;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 2px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    color: #5e5e5e;
}

.check-box {
    margin-right: 10px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-search-title {
    width: 24%;
    background: transparent;
    color: #eeeeee;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
}

.expert-filter-search-title {
    width: 26%;
    background: transparent;
    color: #4e81ce;
    height: 60%;
    display: flex;
    justify-content: center;
    margin-right: 14px;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4px;
}

.demand-filter-search-title {
    width: 26%;
    background: transparent;
    color: #ffffff;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 6px;
}

.policy-filter-search-title {
    width: 400px;
    background: transparent;
    color: #ffffff;
    height: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 6px;
}

.filter-search-input {
    width: 50%;
    background: transparent;
    height: 50%;
    display: flex;
    /*justify-content: space-between;*/
    z-index: 10;
}

.filter-search-input-ct {
    width: 79%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-input {
    height: 94%;
    width: 100%;
    outline: none;
    border: 0;
    padding: 0 0 0 30px;
    font-size: 18px;
    background: rgba(52, 125, 210, 0.5);
    box-shadow: 0 0 10px rgba(35, 35, 35, 0.3);
    border-radius: 6px;
    letter-spacing: 2px;
    color: #e3e3e3;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.expert-filter-input {
    height: 40px;
    width: 100%;
    outline: none;
    border: 0;
    padding: 0 0 0 30px;
    font-size: 20px;
    background: white;
    letter-spacing: 2px;
    color: #e3e3e3;
    transition-property: width, background, opacity, height, box-shadow;
    transition-duration: 100ms;
    transition-timing-function: linear, ease;
}

.policy-filter-input {
    height: 46px;
    width: 90%;
    outline: none;
    border: 0;
    padding: 0 0 0 30px;
    font-size: 20px;
    background: white;
    letter-spacing: 2px;
    color: #e3e3e3;
    transition-property: width, background, opacity, height, box-shadow;
    transition-duration: 100ms;
    transition-timing-function: linear, ease;
}

.policy-filter-search-bt {
    height: 46px;
    width: 100px;
    color: #eeeeee;
    background: #0066ff;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    letter-spacing: 10px;
    text-align: center;
    text-indent: 10px;
    font-weight: bold;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.filter-input:focus {
    box-shadow: none;
    height: 100%;
    background: white;
    outline: none;
    font-size: 18px;
    color: #005fc9;
    border-radius: 0;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.policy-filter-input:focus {
    height: 46px;
    background: white;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.4);
    color: #005fc9;
    transition-property: width, background, opacity, height, box-shadow;
    transition-duration: 100ms;
    transition-timing-function: linear, ease;
}

.expert-filter-input:focus {
    height: 40px;
    background: white;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.4);
    color: #005fc9;
    transition-property: width, background, opacity, height, box-shadow;
    transition-duration: 100ms;
    transition-timing-function: linear, ease;
}

.filter-search-input-bt {
    width: 20%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.geo-ct {
    background: rgba(63, 131, 215, 0.8);
    display: flex;
    color: #ffffff;
    flex-direction: column;
    padding: 10px 0 10px 0;
    min-width: 180px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    height: 350px;
    overflow-y: auto;
}

.city-tag {
    padding: 6px 10px 6px 10px;
}

.city-ct {
    background: rgba(53, 127, 213, 0.9);
    display: flex;
    color: #ffffff;
    flex-direction: column;
    margin-left: 20px;
    padding: 10px 0 10px 0;
    min-width: 180px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    height: 350px;
    overflow-y: auto;
}

.city-ct::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.city-ct::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.city-ct::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
    background-color: transparent;
}

.geo-ct::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.geo-ct::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.geo-ct::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
    background-color: transparent;
}

.geo-outer {
    position: absolute;
    letter-spacing: 2px;
    /*height: 500px;*/
    margin-left: -14px;
    background: transparent;
    padding: 14px 0 20px 0;
    animation: filter-geo-amin 200ms 1 linear;
    -webkit-animation: filter-geo-amin 200ms 1 linear;
}

.geo-province {
    padding: 6px 4px 6px 10px;
    display: flex;
    align-items: center;
}

.geo-cities {
    position: absolute;
    letter-spacing: 2px;
    background: transparent;
    margin-left: 160px;
    top: 0;
    padding: 14px 0 20px 0;
    z-index: 1;
}

.filter-area {
    width: 90%;
    height: 46px;
    margin-top: 20px;
    margin-bottom: 10px;
    background: transparent;
    z-index: 3;
    display: flex;
    align-items: center;
}

.policy-filter-area {
    width: 90%;
    height: 60px;
    background: transparent;
    z-index: 3;
    display: flex;
    align-items: center;
}


.filter-area-geo-title {
    background: transparent;
    padding: 4px 10px 4px 4px;
    font-size: 14px;
    letter-spacing: 2px;
}


.filter-area-geo {
    background: #4781c9;
    padding: 8px 14px 8px 14px;
    font-size: 14px;
    letter-spacing: 2px;
    color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

@keyframes filter-geo-amin {
    0% {
        transform: translate(0, 10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.geo-province:hover {
    background: #5e7ba7;
}

.city-tag:hover {
    background: #6e8cb8;
}

.filter-search-bt {
    height: 70%;
    width: 84px;
    color: #eeeeee;
    background: #1a55bb;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    letter-spacing: 10px;
    text-align: center;
    text-indent: 10px;
    font-weight: bold;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.filter-search-bt:hover {
    width: 84px;
    height: 70%;
    background: #4278d2;
    box-shadow: 0 0 10px rgba(35, 35, 35, 0.3);
    border-radius: 6px;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.expert-filter-search-bt {
    height: 40px;
    width: 84px;
    color: #eeeeee;
    background: #4e81ce;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: space-evenly;
    letter-spacing: 10px;
    text-align: center;
    text-indent: 10px;
    font-weight: bold;
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.policy-filter-search-bt:hover {
    width: 100px;
    height: 46px;
    background: #4278d2;
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.6);
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.expert-filter-search-bt:hover {
    width: 84px;
    height: 40px;
    background: #4278d2;
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.6);
    transition-property: width, background, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.filter-sign {
    width: 30%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-sign-expert {
    width: 40%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    text-indent: 4px;
    width: 140px;
    height: 36%;
    background: transparent;
    color: white;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
    transition-property: width, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.filter-tag:hover {
    box-shadow: 0 0 10px rgba(35, 35, 35, 0.6);
    width: 160px;
    font-weight: bold;
    background: #f3f3f3;
    border-radius: 6px;
    color: #005ec7;
    transition-property: width, opacity, height;
    transition-duration: 200ms;
    transition-timing-function: linear;
}

.expert-filter-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    margin: 8px;
    text-indent: 2px;
    width: 130px;
    height: 40px;
    background: rgba(56, 89, 139, 0.4);
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
    transition-property: box-shadow;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
    font-weight: bold;
}

.expert-filter-tag:hover {
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.6);
    background: #3c80d4;
}

.demand-filter-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    margin: 8px;
    text-indent: 2px;
    width: 130px;
    height: 46px;
    background: rgba(0, 75, 206, 0.6);
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #2d8ae3;
    z-index: 2;
    transition-property: box-shadow;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
    font-weight: bold;
}

.demand-filter-tag:hover {
    box-shadow: 1px 1px 4px rgba(35, 35, 35, 0.6);
}

.filter-item {
    width: 90%;
    height: 44px;
    background: rgba(255, 255, 255, 0.4);
    z-index: 2;
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
}

.filter-item-title {
    height: 100%;
    width: 120px;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    text-indent: 2px;
    font-size: 14px;
    font-weight: bold;
    color: #575757;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 2;
    transition-property: background, opacity;
    transition-duration: 200ms;
}

.filter-more-item-outer {
    background: rgba(255, 255, 255, 0.4);
    display: table-cell;
    width: 90%;
    padding-left: 130px;
    box-sizing: border-box;
}

.num-bt-outer {
    max-width: 74%;
    min-width: 100px;
    height: 100%;
    background: transparent;
    display: flex;
    /*justify-content: space-evenly;*/
    /*align-items: center;*/
}

.check-move-bt {
    font-size: 16px;
    height: 40px;
    width: 40px;
    background: #3c80d4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px 4px 5px 4px;
}

.num-input-bt-outer {
    width: 20%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
}

.num-input-win {
    background: transparent;
    font-size: 16px;
    border: 2px solid #9fccff;
    height: calc(30px - 4px);
    width: calc(50px - 4px);
    margin: 10px 6px 10px 0;
    padding: 0 0 0 10px;
}

.num-input-win-go {
    height: 30px;
    border: 0;
    width: 60px;
    cursor: pointer;
    margin: 10px 0 10px 0;
    letter-spacing: 4px;
    text-indent: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #3c80d4;
}

.num-input-win:focus {
    outline: none;
}

.filter-more-ul {
    width: 96%;
    /*height: 220px;*/
    margin: 30px auto 50px auto;
    overflow-y: auto;
    animation: filter-more 300ms 1 linear;
    -webkit-animation: filter-more 300ms 1 linear;
}

@keyframes filter-more {
    0% {
        opacity: 0;
    }

    96% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.filter-item-sub {
    height: 80%;
    background: transparent;
    display: flex;
    z-index: 2;
    align-items: center;
    letter-spacing: 2px;
    text-indent: 2px;
    font-size: 14px;
    margin-left: 3%;
    cursor: pointer;
}

.filter-item-sub:hover {
    color: #0055ff;
}

.filter-item-more {
    height: 44px;
    min-width: calc(1200px * 0.9);
    max-width: calc(1800px * 0.9);
    width: calc(100% * 0.8 * 0.9);
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    position: absolute;
    color: #656565;
}

.filter-more-bt {
    height: 44px;
    width: 44px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 2px 2px 6px rgba(45, 45, 45, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.filter-sub-more-item {
    background: transparent;
    padding: 18px 10px 18px 10px;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
}

.filter-sub-more-item:hover {
    color: #0055ff;
}

.filter-selected-outer {
    width: 80%;
    background: transparent;
    min-width: 1200px;
    max-width: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.option-tag-outer {
    height: 50px;
    background: transparent;
    display: flex;
    align-items: flex-end;
}

.option-tag {
    padding: 6px 8px 6px 8px;
    margin: 0 10px 0 10px;
    border: 1px solid #6f9fff;
    width: auto;
    font-size: 12px;
    letter-spacing: 2px;
    color: #2968b4;
}

.option-tag-all {
    padding: 7px 0 8px 10px;
    margin: 0 0 0 10px;
    width: auto;
    height: 30px;
    font-size: 12px;
    letter-spacing: 2px;
    color: #f5f5f5;
    background: #89b6e5;
    box-sizing: border-box;
}

.option-tag-delete {
    height: 100%;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
}

.data-tag-title {
    height: 60px;
    background: transparent;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    font-size: 14px;
}

.select-txt{
    width: 90%;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    font-family: MicrosoftYaHei;
}

.select-arrow-icon {
    position: relative;
    font-size: 14px;
    color: #000000;
}

.option-wrap {
    z-index: 10000;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 165px;
    left: 225px;
    width: 150px;
    height: auto;
    max-height: 120px;
    border-radius: 4px;
    border: 1px solid gray;
    background-color: white;
    overflow-y: auto;
}

.option-wrap::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.option-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.option-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(63, 63, 63, 0.9);
    background-color: transparent;
}

.option-item {
    padding-left: 10px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    /*font-family: MicrosoftYaHei;*/
}

.option-item:hover {
    background-color: #2371e8;
    color: white;
}

.lunch-win-out {
    position: absolute;
    left: 0;
    top: 0;
    /*bottom: 0;*/
    z-index: 999;
    width: 100%;
    height: 100%;
    min-width: 1450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(159, 159, 159, 0.5);
}

.pay-way-plugin {
    z-index: 120;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 250px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.pay-way-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 22px 30px;
    background: url("../assets/img/rank_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 2px;
    /*font-family: MicrosoftYaHei;*/
    color: #ffffff;
}

.pay-way-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 120px;
}

.pay-way-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 70px;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    box-sizing: border-box;
}

.pay-way-item-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 70px;
    border-radius: 4px;
    border: 2px solid #00aeec;
    cursor: pointer;
    box-sizing: border-box;
}

/*.pay-way-item:hover {*/
/*    background: #f1f1f1;*/
/*    box-shadow: 0 0 2px 0 #cbcbcb;*/
/*}*/

.next-step-btn {
    margin: 0 auto;
    width: 150px;
    height: 50px;
    background: #00aeec;
    border-radius: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.checkbox-circle {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    font-size: 12px;
    padding: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    -webkit-appearance: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transition: background-color ease 0.1s;
    transition: background-color ease 0.1s;
    outline: none;
}

.checkbox-circle:checked {
    background:#1673ff;
}

.checkbox-circle:checked::after {
    content: '';
    top: 5px;
    left: 5px;
    width: 10px;
    height: 6px;
    position: absolute;
    background: transparent;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.pay-img {
    width: 40px;
    height: 40px;
}

.pay-txt {
    margin-left: 10px;
    font-size: 18px;
}

.qr-plugin {
    z-index: 120;
    position: absolute;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    top: 50%;
    left: 50%;
    width: 500px;
    height: 500px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.qr-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /*align-items: flex-start;*/
    margin: 0 auto;
    width: 90%;
    height: 440px;
}

.qr-info {
    position: relative;
    width: 100%;
    height: auto;
}

.qr-info-top {
    position: relative;
    padding: 15px;
    border-radius: 4px;
    background: #f4f5f7;
}

.qr-info-bottom {
    margin: 10px 0;
    padding-left: 20px;
}

.pay-num {
    padding-left: 10px;
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    color: #00aeec;
}

.qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 2px solid #00a1ff;
    border-radius: 8px;
    box-sizing: border-box;
    /*overflow: hidden;*/
}

.qr-box::before {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 30px;
    width: calc(100% - 60px);
    content: "";
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    z-index: 0;
}

.qr-box::after {
    position: absolute;
    top: 30px;
    right: -2px;
    left: -2px;
    height: calc(100% - 60px);
    content: "";
    border-right: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    z-index: 0;
}

.qr-text {
    margin-top: 20px;
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: bolder;
    color: #02193d;
}

.qr-info-tip {
    padding-bottom: 10px;
    border-bottom: 1px dashed #e7e7e7;
    line-height: 20px;
}

.pay-time {
    font-size: 16px;
    font-weight: bold;
    color: #00aeec;
}

.order-info-id {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
}

.qr-status-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.qr-status-icon {
    width: 20px;
    height: 20px;
}

.qr-sample {
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}