.demand-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1200px;
}

.demand-ct-ad {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 340px;
    height: 1230px;
    background: transparent;
}

.demand-ct-ad-one {
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.demand-ct-ad-two {
    margin-top: 30px;
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.demand-page-content {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1234px;
    background: transparent;
    display: flex;
    justify-content: space-between;
}

.demand-data-item {
    width: 50%;
    height: 260px;
    max-width: 640px;
    background: transparent;
    margin: 0 0 10px 0;
    display: flex;
}

.demand-item-ct {
    width: 94%;
    min-width: 420px;
    max-width: 550px;
    height: 86%;
    background: transparent;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.demand-item-title {
    position: relative;
    height: 26%;
    width: 100%;
    /*background: rgb(91, 138, 208);*/
    color: white;
    font-weight: bold;
    letter-spacing: 2px;
    text-indent: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0575E6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #30448c, #2f88e1);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #30448c, #2f88e1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
}

.demand-item-title-ct {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 70px;
    width: 100%;
    height: 100%;
    line-height: calc(260px * 0.86 * 0.26);
    text-align: center;
    box-sizing: border-box;
    /*text-indent: -40px;*/
    background: transparent;
}

/*.demand-item-title-ct:hover {*/
/*    width: calc(100% - 30px);*/
/*    background: transparent;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    text-indent: -40px;*/
/*}*/

.save-demand {
    position: absolute;
    width: 70px;
    font-size: 12px;
    background: #f3f3f3;
    letter-spacing: 1px;
    height: 22px;
    display: flex;
    text-indent: 1px;
    align-items: center;
    justify-content: center;
    color: #5c81a7;
    transform: translate(0, -32px);
}

.demand-item-title-save {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 100%;
    background: transparent;
    cursor: default;
}

.demand-item-info {
    height: 74%;
    width: 100%;
    /*background: #ffffff;*/
    /*border-left: 1px solid rgba(222, 222, 222, 0.8);*/
    /*border-right: 1px solid rgba(222, 222, 222, 0.8);*/
    /*border-bottom: 1px solid rgba(222, 222, 222, 0.8);*/
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-image: url("../../assets/img/demand_page_bg.jpg");
    background-size: contain;
}

.demand-item-info-line {
    height: 32%;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
}

.demand-item-info-line-half {
    height: 100%;
    width: 50%;
    background: transparent;
    display: flex;
    align-items: center;
}

.demand-item-info-title {
    font-size: 14px;
    letter-spacing: 2px;
    /*padding-right: 10px;*/
    padding-left: 20px;
}

.demand-price {
    font-size: 18px;
    font-weight: bold;
    color: red;
    /*letter-spacing: 1px;*/
}

.demand-item-info-ct {
    padding: 0 10px;
    height: 27px;
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 2px;
    text-indent: 2px;
    box-sizing: border-box;
}

.demand-ct-data {
    min-height: 940px;
    width: calc(100% - 340px);
    background: transparent;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}

.demand-bk {
    width: 100%;
    height: 280px;
    /*background: rgb(99, 129, 168);*/
    /*background: rgb(69, 110, 162);*/
    display: flex;
    z-index: -1;
    justify-content: center;
    background: #0575E6;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #7cabd4, #4386c2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #7cabd4, #4386c2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.demand-banner {
    position: absolute;
    height: 280px;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    display: flex;
}

.demand-banner-img-one {
    /*position: absolute;*/
    height: 280px;
    /*max-width: 1800px;*/
    object-fit: cover;
    overflow: hidden;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.demand-banner-img-two {
    /*position: absolute;*/
    height: 280px;
    margin-left: calc(100% - 381px - 197px);
    /*max-width: 1800px;*/
    object-fit: cover;
    overflow: hidden;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}


.demand-filter-ct {
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    /*min-height: 308px;*/
    background: transparent;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.demand-filter-outer {
    width: 100%;
    background: rgba(228, 234, 239, 10);
    min-height: 418px;
    align-items: center;
    animation: open-demand-anim 400ms 1 ease-in;
    -webkit-animation: open-demand-anim 400ms 1 ease-in;
}

.demand-filter-outer-layout {
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: filter-demand-anim 400ms 1 linear;
    -webkit-animation: filter-demand-anim 400ms 1 linear;
}

@keyframes open-demand-anim {
    0%{
        transform: scale(1, 0);
    }
    100%{
        transform: scale(1, 1);
    }
}

@keyframes filter-demand-anim {
    0%{
        opacity:0;
    }

    96%{
        opacity:0;
    }

    100%{
        opacity:1;
    }
}

.demand-filter-open {
    height: 40px;
    background: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    color: #3c80d4;
}

.demand-filter-open-outer {
    background:transparent;
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
}

.demand-filter-title {
    letter-spacing: 2px;
    padding: 6px 8px 6px 0;
    background: transparent;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}


.demand-header {
    background: #4b6cb7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8b9eb5, #2d497c);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8b9eb5, #2d497c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /*background: #1e3c72;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #2a5298, #1e3c72);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #2a5298, #1e3c72); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
}

.demand-name {
    /*background-image: linear-gradient(111.95deg, #D6FF5B, #14FEC6);*/
    /*color: transparent;*/
    /*-webkit-background-clip: text;*/
    color: white;
}

.ellipse {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}