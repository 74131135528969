.news-data-item {
    width: 98%;
    height: 220px;
    background: transparent;
    margin: 10px 0 46px 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.news-img-outer {
    width: 340px;
    height: 220px;
    background: transparent;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-img-inner {
    width: 300px;
    height: 190px;
    background: transparent;
    overflow: hidden;
    border-radius: 8px;
}

.news-item-ct-outer {
    height: 189px;
    width: calc(100% - 360px);
    background: transparent;
    border-bottom: 1px solid rgba(132, 177, 232, 0.4);
}

.news-item-title {
    width: 100%;
    height: 32%;
    background: transparent;
    font-weight: bold;
    line-height: 28px;
    font-size: 16px;
    color: #2a65ac;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    cursor: pointer;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
}

.news-item-title:hover {
    color: #006def;
}

.news-item-des {
    width: 100%;
    height: 52%;
    background: transparent;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 24px;
    display: flex;
    align-items: center;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
}

.news-item-tag {
    width: 100%;
    height: 16%;
    background: transparent;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgb(88, 130, 182);
}

.news-item-img {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    transition-property: transform;
    -moz-transition-property: transform; /* Firefox 4 */
    -webkit-transition-property: transform; /* Safari and Chrome */
    -o-transition-property: transform; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.news-ct-data-menu {
    height: 40px;
    width: 98%;
    background: transparent;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgba(132, 177, 232, 0.4);
}

.news-page-content {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1134px;
    background: transparent;
    display: flex;
    justify-content: space-between;
}


.news-menu-item {
    width: 88px;
    height: 100%;
    background: transparent;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 4px;
    text-indent: 4px;
    color: #6d6d6d;
    cursor: pointer;
}

.news-menu-sp {
    width: 1px;
    margin-right: 20px;
    margin-left: 20px;
    height: 50%;
    background: #d0d0d0;
}

.news-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1200px;
}

.news-ct-data {
    min-height: 1134px;
    width: calc(100% - 340px);
    background: transparent;
}

.news-banner {
    display: flex;
    align-items: center;
    height: 240px;
    min-width: 1200px;
    width: 100%;
    max-width: 2000px;
    /*background: grey;*/
    background-image: url("../../assets/news/header_banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.news-banner-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 50%;
    width: auto;
    height: 60%;
    background: transparent;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 6px;
    color: #fff;
}

.news-banner-img {
    height: 100%;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    background: #bdecff;
}

.news-ct-ad {
    height: 1130px;
    width: 340px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.news-ct-ad-one {
    width: 100%;
    height: 600px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.news-ct-ad-two {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}
