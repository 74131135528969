.center-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    background: #53657d;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    z-index: 999;
}

.center-header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 174px;
    height: 40px;
    margin-top: 6px;
    margin-left: 20px;
    /*background: #a3dbff;*/
    cursor: pointer;
}

.center-header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0;
    width: auto;
    height: 100%;
    background: transparent;
    box-sizing: border-box;
}

.center-header-item {
    display: flex;
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    cursor: pointer;
}

.logout-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-size: 14px;
    color: white;
}

.center-container {
    position: relative;
    display: flex;
    margin-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    min-width: 1450px;
    background: #f5f5f5;
}

.center-left-menu {
    height: calc(100vh - 60px);
    width: 260px;
    overflow-x: hidden;
    background: #ffffff;
    display: flex;
    transition-property: width;
    -moz-transition-property: width; /* Firefox 4 */
    -webkit-transition-property: width; /* Safari and Chrome */
    -o-transition-property: width; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.center-sub-items {
    background: rgba(207, 216, 224, 0.8);
    transition-property: opacity;
    -moz-transition-property: opacity; /* Firefox 4 */
    -webkit-transition-property: opacity; /* Safari and Chrome */
    -o-transition-property: opacity; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.center-menu-ct {
    width: calc(100% - 18px);
    height: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: hidden;
}

.center-menu-title {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    letter-spacing: 4px;
    width: calc(100% - 20px);
    background: transparent;
    border-bottom: 1px solid #d0d0d0;
    color: #4c4c4c;
    font-weight: bold;
    min-height: 50px;
}

.center-menu-items-outer {
    background: transparent;
    width: 100%;
    margin-top: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    /*min-height: 500px;*/
}

.center-menu-items-outer::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.center-menu-items-outer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.center-menu-items-outer::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.center-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    height: 46px;
    font-size: 14px;
    letter-spacing: 2px;
    width: calc(100% - 20px);
    background: transparent;
    color: #4c4c4c;
    cursor: pointer;
}

.center-menu-item:hover {
    background: rgba(165, 206, 255, 0.1);
    font-weight: bold;
}

.center-menu-item-sub:hover {
    background: rgba(97, 184, 251, 0.1);
    font-weight: bold;
}

.center-menu-item-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    height: 46px;
    font-size: 14px;
    letter-spacing: 2px;
    width: calc(100% - 20px);
    color: #4c4c4c;
    cursor: pointer;
    transition-property: opacity;
    -moz-transition-property: opacity; /* Firefox 4 */
    -webkit-transition-property: opacity; /* Safari and Chrome */
    -o-transition-property: opacity; /* Opera */
    transition-delay: 150ms;
    -moz-transition-delay: 150ms;
    -webkit-transition-delay: 150ms;
    -o-transition-delay: 150ms;
}

.center-menu-bt-outer {
    width: 18px;
    height: 100%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.center-menu-bt {
    display: flex;
    align-items: center;
    font-size: 20px;
    height: 100px;
    width: 100%;
    color: #868686;
    background: #e8e8e8;
    border-bottom: 20px solid #f5f5f5;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f5f5f5;
    cursor: pointer;
}

.center-right-ct {
    padding: 20px 20px;
    width: calc(100% - 300px);
    height: calc(100vh - 100px);
    background-color: #f5f5f5;
    overflow-y: auto;
    transition-property: width;
    -moz-transition-property: width; /* Firefox 4 */
    -webkit-transition-property: width; /* Safari and Chrome */
    -o-transition-property: width; /* Opera */
    transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transition-timing-function: linear, ease-in-out;
    -moz-transition-timing-function: linear, ease-in-out;
    -webkit-transition-timing-function: linear, ease-in-out;
    -o-transition-timing-function: linear, ease-in-out;
}

.center-ct-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 40px;
}

.center-ct-header-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
}

.center-ct-header-icon {
    width: 4px;
    height: 21px;
    background: rgb(60, 128, 212);
}

.center-ct-header-title {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bolder;
    letter-spacing: 2px;
}

.center-ct-header-subtitle {
    margin-left: 15px;
    font-size: 12px;
    color: #909090;
    letter-spacing: 4px;
}

.center-ct-header-fun {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    width: auto;
}

.center-ct-header-fun-title {
    margin-right: 10px;
    font-size: 14px;
    color: #000000;
}

/* Overview */
.overview-ct-wrap {
    height: 100%;
    overflow-x: auto;
    /*overflow-y: hidden;*/
}

.overview-ct-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.overview-ct-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

.overview-ct-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.overview-ct {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    /*margin: 0 auto;*/
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 800px;
    background: transparent;
}

.overview-info-ct {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 30px;
    width: calc(40% - 82px);
    height: calc(30% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

.overview-info-avatar {
    width: 50px;
    height: 50px;
}

.overview-info-base {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 20%;
}

.overview-info-base-one {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
}

.overview-info-username {
    font-size: 18px;
    font-weight: bolder;
    color: #000000;
}

.overview-tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: auto;
    height: 22px;
    font-size: 12px;
    text-indent: 1px;
    color: #fafafa;
    background: #000000;
    transform: translate(-31px, -70px);
}

.overview-info-vip {
    margin-left: 12px;
    font-size: 20px;
    color: red;
    cursor: pointer;
    /*border: 1px solid red;*/
}

.overview-info-vip-icon {
    width: 15px;
    height: 15px;
}

.overview-info-certify {
    margin-left: 12px;
    font-size: 20px;
    cursor: pointer;
    /*border: 1px solid #148007;*/
}

.overview-info-certify-icon {
    width: 15px;
    height: 15px;
    font-size: 16px;
}

.overview-info-complete-info {
    margin-left: 12px;
    font-size: 20px;
    color: #081988;
    cursor: pointer;
    /*border: 1px solid #081988;*/
}

.overview-info-complete-info-icon {
    width: 15px;
    height: 15px;
}

.overview-info-coin-com {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    line-height: 40px;
}

.overview-info-coin-com-icon {
    font-size: 20px;
}

.overview-info-coin {
    margin-left: 10px;
    font-size: 14px;
}

.overview-info-vip-date {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #1a55bb;
}

.overview-info-coin-num {
    font-size: 16px;
    font-weight: bold;
    color: #1a55bb;
}

.overview-info-coin-recharge {
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #05734e;
    cursor: pointer;
}

.overview-info-complete {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

.overview-info-complete:hover {
    color: #1a55bb;
}

.overview-info-complete-icon {
    font-size: 20px;
}

.overview-info-complete-title {
    margin-left: 10px;
    font-size: 14px;
}

.overview-board-ct {
    padding: 20px 30px;
    width: calc(60% - 62px);
    height: calc(30% - 42px);
    background: #ffffff;
}

.overview-board-action {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 80%;
    /*background: #6e86a7;*/
}

.overview-board-action-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 45%;
}

.overview-board-action-num-com {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.overview-board-action-num {
    height: 40px;
    font-size: 18px;
    font-weight: bolder;
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
    letter-spacing: 2px;
    color: #0066ff;
    line-height: 40px;
    cursor: pointer;
}

.overview-board-action-num:hover {
    color: #022152;
}

.overview-board-action-title {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
}

.overview-rank-ct {
    padding: 20px 30px 10px;
    width: 100%;
    height: calc(70% - 22px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    box-sizing: border-box;
}

.rank-plugin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    width: auto;
    height: calc(100% - 50px);
    min-width: 800px;
    max-width: 1200px;
    /*min-height: 470px;*/
    /*max-height: 490px;*/
}

.rank-plugin-wrap {
    width: 32%;
    height: 100%;
    min-width: 250px;
    max-width: 300px;
}

.rank-plugin-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bolder;
    font-family: Apple, sans-serif;
    text-indent: 10px;
    letter-spacing: 10px;
    color: #ffffff;
    /*background-image: url("../../assets/img/rank_header_one.png");*/
    /*background-color: #267523;*/
    border-radius: 12px 12px 0 0;
}

.img_0 {
    background-image: url("../../assets/img/rank_header_one.png");
}

.img_1 {
    background-image: url("../../assets/img/rank_header_two.png");
}

.img_2 {
    background-image: url("../../assets/img/rank_header_three.png");
}

.rank-shadow {
    position: relative;
    /*width: 100%;*/
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    /*border-bottom:20px solid;*/
}

.rank-shadow-item {
    position: absolute;
    width: 100%;
    height: 10px;
    transform: translate(0, -10px);

}

.shadow_0 {
    border-top: 10px solid #005404;
}

.shadow_1 {
    border-top: 10px solid #855e2e;
}

.shadow_2 {
    border-top: 10px solid #0541a1;
}

.shadow_item_0 {
    background: -webkit-linear-gradient(to bottom, #14a91c 30%, #d6f5db 70%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #14a91c 30%, #d6f5db 70%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.shadow_item_1 {
    background: -webkit-linear-gradient(to bottom, #c7a479 30%, #fcfbf0 70%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #c7a479 30%, #fcfbf0 70%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.shadow_item_2 {
    background: -webkit-linear-gradient(to bottom, #a4bcd9 30%, #f0f3f8 70%);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #a4bcd9 30%, #f0f3f8 70%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.rank-plugin-main {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: calc(100% - 40px);
    height: calc(91% - 60px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.main_0 {
    background-color: #f4fff6a6;
}

.main_1 {
    background-color: #fbf9ebbf;
}

.main_2 {
    background-color: #f0f3f8;
}

.rank-plugin-main-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: calc(100% / 10);
    box-sizing: border-box;
}

.rank-plugin-main-item-rank {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 14px;
    font-weight: bolder;
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
    color: #000000;
    border-radius: 50%;
    /*background-color: #AEB3B9;*/
}

.rank-plugin-main-item-rank-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.rank-plugin-main-item-name {
    width: 30%;
    text-align: left;
}

.rank-plugin-main-item-points {
    width: 35%;
    text-align: right;
}

.rank-plugin-main-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bolder;
}

.rank-plugin-self {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    width: 100%;
    height: 9%;
    background: #0e6212;
    box-sizing: border-box;
    box-shadow: 0 -3px 8px rgba(63, 67, 143, 0.5);
}

.self_0 {
    background: #0f9b0f;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #375844, #0f9b0f, #375844);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #375844, #0f9b0f, #375844); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.self_1 {
    background: #e3cd67;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #c18943, #e3cd67, #e8d06e);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #c18943, #e3cd67, #e8d06e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.self_2 {
    background: #0064e5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0f7ced, #0064e5, #288afa);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0f7ced, #0064e5, #288afa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.rank-plugin-self-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.rank-plugin-self-icon {
    width: 30px;
    height: 30px;
}

.rank-plugin-self-title {
    margin-left: 20px;
    width: auto;
    font-size: 14px;
    font-weight: bolder;
    color: #ffffff;
}

.rank-plugin-self-rank {
    font-size: 14px;
    font-weight: bolder;
    font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif;
    letter-spacing: 2px;
    color: #ffffff;
}

/* Account */
.account-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    overflow-y: auto;
}

.account-ct-ct {
    margin: 20px 0 0 0;
    padding: 0;
    width: calc(100% - 40px);
    height: auto;
}

.account-ct-user-setting-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 1px;
    color: #6d6d6d;
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}

.account-title-l {
    margin-right: 40px;
    width: auto;
}

.account-title-r {
    width: auto;
    font-size: 14px;
    letter-spacing: 1px;
    color: #a6a6a6;
}

.account-ct-user-setting-core {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
    width: 100%;
    height: auto;
}

.account-ct-user-setting-core-ele {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    width: 600px;
    height: 60px;
}

.account-ct-user-setting-core-l {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
}

.account-ct-user-setting-core-m {
    display: flex;
    align-items: center;
    width: 45%;
    font-size: 12px;
    color: #6d757a;
}

.account-ct-user-setting-core-r {
    width: 20%;
    font-size: 12px;
    color: #00a1d6;
    cursor: pointer;
}

.account-ct-user-setting {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
    height: auto;
}

.account-ct-user-setting-ele {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
}

.account-ct-user-setting-label {
    text-align: right;
    padding: 0 15px;
    width: 120px;
    font-size: 14px;
}

.account-ct-user-setting-input {
    padding: 3px 10px;
    width: 400px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.account-ct-user-setting-input::placeholder {
    font-size: 14px;
    letter-spacing: 1px;
}

.account-ct-user-setting-input:hover {
    border: 1px solid #8391a5;
}

.account-ct-user-setting-input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.long-txt {
    width: 700px;
    height: auto;
    word-wrap: break-word;
    word-break: normal;
}

.account-ct-checkbox-wrap {
    margin-left: 150px;
    width: 600px;
    height: auto;
}

.account-ct-checkbox-ct {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 12px;
}

.geo-select-wrap {
    display: flex;
    flex-direction: row;
}

.select-wrap {
    display: flex;
    flex-direction: row;
    margin-left: 150px;
    width: 500px;
    height: 200px;
}

.account-ct-select-wrap {
    width: 160px;
    height: 200px;
    border-radius: 4px;
    border: 1px solid #8391a5;
    overflow-y: scroll;
    box-sizing: border-box;
}

.account-ct-select-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.account-ct-select-wrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

.account-ct-select-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.account-ct-select-ct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    font-size: 12px;
}

.area-geo-province {
    padding: 6px 4px 6px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.area-geo-province:hover {
    background: #1e64cc;
    color: white;
}

.area-geo-cities {
    width: 100%;
    height: 100%;
}

.area-city-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.area-city-item {
    padding: 6px 4px 6px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.area-city-item:hover {
    background: #1e64cc;
    color: white;
}

.form-plugin-btn-wrap-submit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto 30px;
    padding-left: 150px;
    width: 95%;
    box-sizing: border-box;
}

.account-ct-button-wrap {
    display: flex;
    flex-direction: row;
    margin: 50px 0 0 130px ;
}

.account-ct-button-other-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.center-ct-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100px;
    height: 38px;
    border-radius: 4px;
    border: 0;
    color: #ffffff;
    font-size: 14px;
    background-color: #00a1d6;
    cursor: pointer;
}

.center-ct-button:hover {
    background-color: #00b5e5;
}

.modify-button {
    background: #4eb341;
}

.modify-button:hover {
    background: #3a832e;
}

.save-button {
    background: #00a1d6;
}

.save-button:hover {
    background: #338fd7;
}

.save-button-none {
    pointer-events: none;
    background: #6e6e6e;
}

.cancel-button {
    background: #e1cc4d;
}

.cancel-button:hover {
    background: #c2b041;
}

/* account modal */
.account-modal-bg {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
}

.account-modal-ct {
    z-index: 100;
    position: absolute;
    /*padding: 20px 30px;*/
    top: 50%;
    left: 50%;
    /*margin: 0 auto;*/
    /*padding: 20px 30px;*/
    width: 600px;
    height: 400px;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
    border-radius: 5px;
    box-sizing: border-box;
}

.account-modal-close-wrap {
    z-index: 99;
    position: absolute;
    top: calc(50% - 170px);
    left: calc(50% + 260px);
    width: auto;
    height: 40px;
}

.account-modal-close {
    font-size: 20px;
    color: rgb(72, 72, 72);
    cursor: pointer;
}

.account-modal-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: bolder;
    line-height: 50px;
}

.icon-style-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #518cd5;
}

.icon-style {
    font-size: 14px;
    color: white;
}

.schedule-modal-setting-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto 0;
    width: 100%;
    height: auto;
}

.send-code-msg {
    margin-bottom: 8px;
    width: 60%;
    height: 30px;
    font-size: 14px;
    color: #4d4d4d;
    letter-spacing: 0;
    line-height: 22px;
}

.send-code-tip {
    width: 60%;
    height: 30px;
    font-size: 14px;
    color: #ccc;
    letter-spacing: 0;
    line-height: 22px;
}

.send-code-ct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0 0;
    width: 60%;
    height: 50px;
}

.send-code-ct-text {
    width: 30%;
    font-size: 14px;
    color: #4d4d4d;
    letter-spacing: 0;
    line-height: 22px;
    vertical-align: middle;
}

.send-code-get {
    position: absolute;
    /*top: 0;*/
    /*right: 25%;*/
    background-color: transparent;
    border: none;
    font-size: 12px;
    color: #3399ea;
    line-height: 24px;
    letter-spacing: 1px;
    transform: translate(280px, 0);
    cursor: pointer;
}

.send-code-next-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    width: 100px;
    height: 38px;
    border-radius: 4px;
    border: 0;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.pwd-eye-out {
    position: absolute;;
    transform: translate(320px, 0);
    font-size: 18px;
    cursor: pointer;
}

/* Certification */
.certification-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    overflow-y: auto;
}

.certification-tip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 40px;
    width: 100%;
    height: auto;
}

.certification-tip-ele {
    font-size: 16px;
    line-height: 30px;
    color: #505050;
}

.certification-table-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto 0;
    width: 60%;
    min-width: 820px;
}

.certification-table {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 24px;
    width: 260px;
    height: 400px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    background-color: #fff;
}

.certification-table-icon {
    width: 68px;
    height: 68px;
}

.certification-table-title {
    font-size: 18px;
    font-weight: bolder;
}

.certification-table-introduce {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    color: #707070;
}

.certification-table-adv {
   text-align: left;
    width: 100%;
}

.certification-table-adv-ele {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    line-height: 30px;
    color: #707070;
}

.cert-process-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px auto 0;
    width: 100%;
    height: auto;
}

.cert-process-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 50px;
    color: #00a1d6;
}

.back-icon {
    font-size: 18px;
    cursor: pointer;
}

.cert-process-icon {
    margin-left: 10px;
    width: 25px;
    height: 21px;
}

.cert-process-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    height: 100%;
}

.cert-process-type-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
}

.cert-process-type-subtitle {
    margin-top: 8px;
    font-size: 14px;
    color: #939393;
}

.cert-process-notice {
    margin: 20px 0 0 0;
    padding: 20px 30px;
    width: calc(100% - 60px);
    height: auto;
    background-color: #f4f4f4;
}

.cert-process-notice-ele {
    width: 100%;
    height: auto;
}

.cert-process-notice-ele-header {
    width: 100%;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #151515;
}

.notice-icon {
    margin-right: 10px;
    font-size: 16px;
    color: red;
}

.cert-process-notice-ele-ct-wrap {
    width: 100%;
    height: auto;
}

.cert-process-notice-ele-ct {
    width: 100%;
    height: 30px;
    font-size: 14px;
}

.cert-process-ct {
    padding: 20px 30px;
    width: calc(100% - 60px);
    height: auto;
}

.cert-process-ct-title {
    margin-bottom: 10px;
    width: auto;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
}

.cert-process-ct-ele {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
}

.auth-comp-license {
    align-items: flex-start;
    padding-top: 10px;
    height: auto;
}

.cert-process-ct-ele-label {
    margin-right: 20px;
    width: auto;
    font-size: 14px;
    letter-spacing: 1px;
}

.auth-comp {
    width: 135px;
}

.cert-process-pic-upload {
    width: 100%;
    height: auto;
}

.cert-process-pic-upload-title {
    margin-bottom: 10px;
    width: auto;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
}

.cert-process-pic-upload-ct {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
}

.cert-process-pic-upload-ele {
    width: 300px;
    height: auto;
    /*background-color: #9fccff;*/
}

.cert-process-pic-upload-img-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.cert-process-pic-upload-img {
    margin-right: 20px;
    padding: 2px 3px;
    width: 249px;
    height: 155px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}

.cert-process-pic-upload-fun {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 0;
    width: auto;
    height: 40px;
}

.cert-process-pic-upload-txt {
    font-size: 14px;
}

.cert-process-pic-upload-bt {
    text-align: center;
    margin-left: 10px;
    width: 70px;
    height: 30px;
    border: 1px solid #ccd0d7;
    border-radius: 4px;
    font-size: 14px;
    line-height: 30px;
    color: #717171;
    cursor: pointer;
}

.input-file {
    display: none;
}

.cert-process-pic-upload-bt:hover {
    color: #ffffff;
    background-color: #9fccff;
}

.cert-process-button-wrap {
    padding: 20px 30px;
    width: calc(100% - 60px);
    height: auto;
}

.cert-check-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto 0;
    width: 60%;
}

.cert-check-img {
    width: 200px;
    height: 200px;
}

.cert-check-txt {
    text-align: center;
    margin: 40px 0;
    width: auto;
    height: auto;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
}

.cert-check-bt {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    width: 90px;
    height: 30px;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #606266;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
}

.cert-check-bt:hover {
    color: #00a1d6;
    border: 1px solid #00a1d6;
}

.cert-user-person-checkbox-wrap {
    display: flex;
    flex-direction: column;
}

.cert-user-person-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
}

.cert-process-org-type-checkbox-wrap {
    display: flex;
    flex-direction: row;
}

.cert-process-org-type-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    font-size: 14px;
}

/* OutcomeMgr */
.outcome-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

.outcome-mgr-ct-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 0 0 0 0;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    box-sizing: border-box;
}

.outcome-mgr-ct-menu-l {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.outcome-mgr-ct-menu-r {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    width: 70px;
    cursor: pointer;
}

.my-service-plugin-menu-ele {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    width: auto;
    height: 70%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    text-indent: 4px;
    color: #6d6d6d;
    cursor: pointer;
}

.my-service-plugin-menu-ele-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    width: auto;
    height: 70%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    text-indent: 4px;
    color: black;
    border-bottom: 3px solid rgb(37, 118, 217);
    cursor: pointer;
}

.form-plugin-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    color: #00a1d6;
}

.outcome-mgr-ct-table {
    margin-top: 20px;
    width: 100%;
}

.table-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.table-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background: rgba(36, 91, 155, 0.8);
    box-sizing: border-box;
}

.table-header-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    /*font-weight: bold;*/
    /*background: rgba(36, 91, 155, 0.8);*/
    box-sizing: border-box;
}

.table-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    box-sizing: border-box;
    word-break: break-all;
}

.index {
    width: 20%;
}

.title {
    width: 30%;
    border: 1px solid rgba(227, 227, 227, 0.8);
    border-top: none;
    border-bottom: none;
}

.type {
    width: 15%;
    border: 1px none rgba(227, 227, 227, 0.8);
    border-right-style: solid;
}

.date {
    width: 15%;
    border: 1px none rgba(227, 227, 227, 0.8);
    border-right-style: solid;
}

.operation {
    width: 20%;
}

.week0 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
    background: #ffffff;
}

.week1 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week2 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week3 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week4 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week5 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week6 {
    width: calc(100% / 8);
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.week7 {
    width: calc(100% / 8);
}

.order-id {
    width: 25%;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.order-time {
    width: 20%;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.order-price {
    width: 8%;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.order-method {
    width: 8%;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
}

.order-service {
    width: 15%;
}

.table-ct {
    position: relative;
    /*display: flex;*/
    /*flex-direction: column;*/
    width: 100%;
    height: calc(100% - 50px);
    font-size: 14px;
    color: rgb(94, 94, 94);
    box-sizing: border-box;
    overflow: auto;
}

.table-ct::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.table-ct::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: transparent;
}

.table-ct::-webkit-scrollbar-thumb {
    border-right: 3px solid rgba(38, 60, 99, 0.2);
    background-color: transparent;
}

.schedule-time-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: calc(100% / 8);
    height: auto;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
    font-size: 14px;
    color: rgb(94, 94, 94);
    box-sizing: border-box;
}

.schedule-table-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(100% / 8 * 7);
    height: calc(50px * 24);
    font-size: 14px;
    color: rgb(94, 94, 94);
    box-sizing: border-box;
}

.table-ct-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    border-top: none;
    font-size: 14px;
    color: rgb(94, 94, 94);
    box-sizing: border-box;
}

.table-ct-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.title-ct {
    text-align: center;
    padding: 0 10px;
    width: 30%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 50px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    border-top: none;
    border-bottom: none;
    box-sizing: border-box;
}

.table-ct-cell-icon {
    color: #1a55bb;
    cursor: pointer;
}

.table-ct-no-data {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    border-top: none;
    box-sizing: border-box;
}

.table-ct-no-data-icon {
    width: 60px;
    height: 60px;
}

.table-ct-no-data-tip {
    font-size: 14px;
    line-height: 50px;
    color: rgb(94, 94, 94);
}

/* DemandMgr */
.demand-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

/* AppointmentMgr */
.appointment-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    /*overflow-y: scroll;*/
}

.appointment-mgr-ct-table {
    margin-top: 20px;
    width: 100%;
    height: calc(100% - 130px);
}

.app-date {
    width: 25%;
}

.app-time {
    width: 25%;
    border: 1px solid rgba(227, 227, 227, 0.8);
    border-top: none;
    border-bottom: none;
}

.app-expert {
    width: 35%;
    border: 1px none rgba(227, 227, 227, 0.8);
    border-right-style: solid;
}

.check-expert-id {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    height: 100%;
    font-size: 12px;
    color: #2245bb;
    cursor: pointer;
}

.expert-id-tooltip {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: auto;
    height: 30px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fafafa;
    background: #262626;
    border-radius: 4px;
    transform: translate(0px, -30px);
    box-sizing: border-box;
}

/* SubscriptionMgr */
.subscription-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    min-height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

.my-service-plugin-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    padding: 0 0 0 0;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(227, 227, 227, 0.8);
}

.sub-mgr-modal-ct-wrap {
    z-index: 5000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    height: auto;
    min-height: 200px;
    transform: translate(-50%, -50%);
    background: #fafafa;
    border-radius: 5px;
    box-sizing: border-box;
    /*overflow: hidden;*/
}

.sub-mgr-modal-ct {
    padding: 20px 20px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.subscription-mgr-ct-button-wrap {
    display: flex;
    flex-direction: row;
    margin: 20px 0 0 0;
}

.subscription-mgr-ct-setting {
    margin-top: 20px;
    width: 100%;
    height: auto;
    background: transparent;
}

.subscription-mgr-ct-setting-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 14px 30px;
    width: 100%;
    min-height: 80px;
    /*border: 1px solid #c4c4c4;*/
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.subscription-mgr-ct-setting-item-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #356fb8;
    padding-top: 6px;
    display: flex;
    align-items: center;
}

.subscription-mgr-ct-setting-item-content {
    margin: 30px 0 30px;
    width: 100%;
    height: 100%;
}

.sub-ct-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.sub-ct-info-item {
    display: flex;
    flex-direction: column;
    /*margin-right: calc(100% / 42);*/
    /*width: calc(100% / 7);*/
    width: 220px;
    height: auto;
    background-color: transparent;
}

.sub-ct-info-item-title {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    line-height: 30px;
}

.sub-title-sign {
    height: 8px;
    width: 8px;
    background: rgb(69,128,229);
    margin-right: 6px;
}

.sub-ct-info-item-text {
    font-size: 12px;
    line-height: 22px;
    margin: 6px 0;
}

.sub-ct-item-span {
    background: #e7f2ff;
    padding: 4px 8px;
    border-radius: 4px;
}

.sub-geo {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 46px;
}

.sub-geo-add{
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
}

.sub-geo-add-tooltip {
    z-index: 100;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: auto;
    height: 22px;
    font-size: 12px;
    text-indent: 1px;
    color: #fafafa;
    background: #000000;
    transform: translate(-15px, -35px);
}

.sub-geo-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
}

.sub-geo-item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    width: auto;
}

.filter-area-sub {
    width: auto;
    height: 46px;
    background: transparent;
    z-index: 3;
    display: flex;
    align-items: center;
}

.filter-area-geo-title-sub {
    display: none;
}

.sub-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.sub-ct-info-fun {
    display: flex;
    align-items: center;
    /*flex-direction: row;*/
    /*justify-content: flex-end;*/
    /*align-items: center;*/
    /*margin-top: 10px;*/
    /*width: 100%;*/
    font-size: 12px;
    height: 30px;
}

.sub-ct-info-fun-modify {
    margin: 0 10px 0 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background: #13ad47;
    height: 20px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}

.sub-ct-info-fun-delete {
    cursor: pointer;
    border-radius: 4px;
    font-weight: normal;
    color: white;
    height: 20px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #cb3333;
}

.subscription-mgr-ct-setting-item-empty {
    width: 100px;
    height: 35px;
}

.subscription-mgr-ct-setting-item-empty-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    color: #343434;
    cursor: pointer;
}

.subscription-mgr-ct-setting-item-empty-btn:hover {
    background-color: #1890ff;
    color: white;
}

.checkbox-wrap {
    width: 100%;
    height: auto;
}

.checkbox-title {
    font-size: 16px;
    font-weight: bolder;
    line-height: 30px;
}

.ant-checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: auto;
    height: 30px;
}

.ant-checkbox-wrapper-disabled {
    color: #858585;
}

/* CollectionMgr */
.collection-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

.collection-mgr-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    padding: 0 0 0 0;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(227, 227, 227, 0.8);
}

/* ScheduleCheck */
.schedule-check-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px 0;
    width: calc(100% - 62px);
    height: calc(100% - 22px);
    min-height: 815px;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
}

.load-plugin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.load-icon {
    font-size: 30px;
    color: #1a55bb;
}

.load-txt {
    margin-top: 30px;
    font-size: 16px;
    color: #1a55bb;
}

.schedule-calendar {
    margin: 10px auto 0;
    width: 100%;
    height: auto;
    background: transparent;
}

.calendar-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.calendar-ct {
    margin: 0 auto;
    width: 95%;
    height: 100%;
    min-height: 500px;
    border: 1px solid #296de6;
}

.calendar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    height: 45px;
}

.calendar-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10%;
    height: 100%;
    min-width: 145px;
    font-size: 18px;
}

.calendar-date-cell {
    width: 48%;
    height: 60%;
    /*min-width: 70px;*/
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    padding: 3px 2px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.calendar-date-cell:hover {
    border: 1px solid #8391a5;
}

.calendar-date-cell:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

/*.select .sch-cal-appoint-select .calendar-date-select-cell {*/
/*    padding: 2px 0 !important;*/
/*    min-height: 50px !important;*/
/*    background: transparent;*/
/*}*/

.calendar-sample {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    min-width: 350px;
}

.calendar-sample-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: auto;
}

.calendar-sample-cell-color {
    width: 10px;
    height: 10px;
}

.calendar-sample-cell-text {
    margin-left: 8px;
    font-size: 14px;
    color: #4c4c4c;
}

.calendar-today {
    background: rgba(46, 100, 236, 0.2);
}

.calendar-empty {
    background: rgba(102, 189, 102, 0.4);
}

.calendar-close {
    background: rgba(255, 230, 144, 0.4);
}

.calendar-full {
    background: rgba(255, 152, 152, 0.4);
}

.calendar-year-switch {
    font-size: 30px;
    color: #4e4e4e;
    cursor: pointer;
}

.calendar-week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    height: 35px;
    border-bottom: 1px solid #bababa;
}

.calendar-week-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 7);
    height: 100%;
    font-size: 14px;
}

.calendar-day {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    height: auto;
}

.calendar-day-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% / 7);
    height: 108px;
}

.calendar-day-cell-date {
    width: 100%;
    height: 20%;
    /*background: #5e7ba7;*/
}

.calendar-day-cell-item {
    width: 100%;
    height: 80%;
    background: rgba(239, 239, 239, 0.4);
    /*cursor: pointer;*/
    overflow-y: auto;
}

/* y轴滚动条 */

.calendar-day-cell-item::-webkit-scrollbar-track {
    background-color: transparent;
}

.calendar-day-cell-item::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

.calendar-day-cell-item::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.last-month {
    color: #cecece;
    background: transparent;
}

.next-month {
    color: #cecece;
    background: transparent;
}

.this-month {
    color: black;
}

.day {
    width: 90%;
    height: 90%;
}

.today {
    background: rgba(46, 100, 236, 0.2);
}

.open-appoint {
    background: rgba(102, 189, 102, 0.4);
}

.no-open {
    background: rgba(255, 230, 144, 0.4);
}

.no-appoint {
    background: rgba(255, 152, 152, 0.4);
}

.no-between {
    /*background: rgba(255, 255, 255, 0.5);*/
}

.selected-day {
    width: 90%;
    height: 90%;
    background: rgba(191, 22, 22, 0.2);
}

.calendar-day-cell-item-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 92%;
    height: 35px;
    min-width: 120px;
    line-height: 35px;
}

.calendar-day-cell-item-cell-icon {
    width: 5px;
    height: 5px;
    border: 0;
    border-radius: 50%;
    background: #2d8ae3;
}

.calendar-day-cell-item-cell {
    font-size: 12px;
}

.appoint-time {
    margin: 0 5px;
    width: auto;
    min-width: 65px;
}

.appoint-user {
    display: -webkit-box;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 30%;
}

.modal-bg {
    z-index: 900;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
}

.sch-cal-modal-bg {
    z-index: 900;
    position: absolute;
    top: -20px;
    left: 0;
    /*left: -27px;*/
    width: 1020px;
    height: 640px;
    /*min-height: 590px;*/
    /*max-height: 700px;*/
    background: rgba(159, 159, 159, 0.5);
}

.modal-header-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100px;
    padding: 22px 30px;
    background: url("../../assets/img/rank_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.sch-cal-modal-ct {
    z-index: 5000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 390px;
    transform: translate(-50%, -50%);
    background: #fafafa;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.sch-cal-appoint-wrap {
    margin: 20px auto 0;
    width: 90%;
    height: 200px;
}

.sch-cal-appoint-ct {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    /*background-color: #00b3ff;*/
}

.sch-cal-appoint-txt {
    text-align: right;
    width: 170px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000000;
}

.sch-cal-appoint-input {
    margin-left: 20px;
    padding: 3px 10px;
    width: 380px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    word-break:break-all;
    white-space: pre-wrap;
}

.sch-cal-appoint-input::placeholder {
    font-size: 14px;
    letter-spacing: 1px;
}

.sch-cal-appoint-input:hover {
    border: 1px solid #8391a5;
    word-break:break-all;
    white-space: pre-wrap;
}

.sch-cal-appoint-input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    word-break:break-all;
    white-space: pre-wrap;
}

.sch-cal-appoint-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding: 0 10px;
    width: 150px;
    height: 30px;
    /*min-width: 70px;*/
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    box-sizing: border-box;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.sch-cal-appoint-select:hover {
    border: 1px solid #8391a5;
}

.sch-cal-appoint-select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.sch-cal-appoint-date {
    margin-left: 20px;
    padding: 0 10px;
    width: 150px;
    height: 30px;
    /*min-width: 70px;*/
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    font-family: MicrosoftYaHei;
    box-sizing: border-box;
}

.sch-cal-appoint-tip {
    margin: 10px 0 0 ;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 40px;
    color: #7e7e7e;
    border-radius: 5px;
    background-color: rgba(178, 178, 178, 0.4);
}

.sch-cal-appoint-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 ;
}

.calendar-modal-ct {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 500px;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-header-title {
    font-size: 16px;
    font-weight: bolder;
    font-family: MicrosoftYaHei;
    letter-spacing: 1px;
    color: #ffffff;
}

.modal-header-subtitle {
    margin-top: 8px;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 1px;
}

.modal-header-txt {
    margin-top: 10px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    letter-spacing: 1px;
    color: #ffffff;
}

.modal-timeline-wrap {
    margin: 20px auto;
    width: 90%;
    height: 360px;
    background: transparent;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal-timeline-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.modal-timeline-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.modal-timeline-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.modal-timeline-ct {
    position: relative;
    /*margin: 0 auto;*/
    width: 100%;
    height: 100%;
    background: transparent;
}

.calendar-modal-timeline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 160px;
}

.calendar-modal-timeline-cell {
    margin: 0 0 0 10px;
    height: 80%;
    font-size: 16px;
    box-sizing: border-box;
}

.timeline-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 20px;
    width: auto;
    font-size: 16px;
    font-weight: bold;
    color: #3b3b3b;
}

.timeline-object {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 30px;
    width: 85%;
    font-size: 14px;
    color: #2a2a2a;
    background: rgba(102, 189, 102, 0.4);
    border-radius: 5px;
}

.timeline-object:hover {
    transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 10%);
}

.calendar-modal-timeline-block {
    width: 28px;
    height: 80%;
    background: rgba(102, 189, 102, 0.4);
}

.calendar-modal-timeline-line {
    width: 2px;
    height: 100%;
    background: #096444;
}

.calendar-modal-timeline-cell-object {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 30px;
    line-height: 30px;
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 600;
}

.calendar-modal-timeline-cell-icon {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 50%;
}

.userDes-userName {
    background-color: #2c2c2c;
}

.userDes-userComp {
    background-color: #4652c7;
}

.userDes-userArea {
    background-color: #925ba2;
}

.userDes-appointTitle {
    background-color: #2a307c;
}

.calendar-modal-close {
    z-index: 99;
    position: absolute;
    top: calc(50% - 250px);
    left:  calc(50% + 370px);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* ScheduleSetting */
.schedule-setting-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: calc(100% - 62px);
    height: calc(100% - 42px);
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    overflow-y: auto;
}

.schedule-setting-ct-table {
    margin-top: 20px;
    width: 100%;
    height: auto;
}

.table-header-cell-text {
    margin-right: 8px;
}

.table-header-cell-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
}

.schedule-week-tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 140px;
    height: 22px;
    font-size: 12px;
    text-indent: 1px;
    color: #fafafa;
    background: rgba(0, 0, 0, 0.8);
    transform: translate(0px, -30px);
}

.time-date {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: calc(100% / 8 - 20px);
    height: 100%;
}

.schedule-time {
    padding: 0 10px;
    /*width: calc(100% - 20px);*/
    width: 100%;
    height: 50px;
    background: rgba(234, 234, 234, 0.5);
    border-left: 1px solid rgba(227, 227, 227, 0.8);
    border-bottom: 1px solid rgba(227, 227, 227, 0.8);
    box-sizing: border-box;
}

.open-time {
    padding: 0 10px;
    width: calc(100% / 7);
    height: 50px;
    /*display: -webkit-box;*/
    /*-webkit-box-orient: vertical;*/
    /*-webkit-line-clamp: 1;*/
    /*overflow: hidden;*/
    line-height: 30px;
    border-right: 1px solid rgba(227, 227, 227, 0.8);
    border-bottom: 1px solid rgba(227, 227, 227, 0.8);
    white-space: pre-line;
    box-sizing: border-box;
}

.schedule-setting-ct-button-wrap {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    width: 250px;
}

.schedule-modal-bg {
    z-index: 90;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
}

.schedule-modal-ct {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    /*margin: 0 auto;*/
    /*padding: 20px 30px;*/
    width: 800px;
    height: 500px;
    transform: translate(-50%, -50%);
    background: white;
    overflow: hidden;
    border-radius: 5px;
    box-sizing: border-box;
}

.schedule-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: bolder;
    line-height: 50px;
}

.schedule-modal-week-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
    width: 90%;
    height: 30px;
}

.schedule-modal-week-ele {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(90% / 7);
    height: 100%;
    font-size: 14px;
    color: rgba(94, 94, 94, 0.5);
    border: 1px solid rgba(36, 112, 159, 0.5);
    border-radius: 5px;
}

.schedule-modal-week-ele-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(90% / 7);
    height: 100%;
    font-size: 14px;
    font-weight: bolder;
    border: 2px solid rgba(85, 160, 222, 0.8);
    border-radius: 5px;
    box-shadow: rgba(122, 184, 227, 0.8) 0 0 4px;
}

.schedule-modal-schedule-by-week {
    position: relative;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    background: transparent;
}

.schedule-modal-schedule-by-week-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background: rgba(187, 187, 187, 0.3);
    border: 1px solid #bababa;
    box-sizing: border-box;
}

.schedule-modal-schedule-by-week-header-ele {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    font-size: 14px;
    font-weight: bold;
    color: #565656;
}


.schedule-modal-schedule-by-week-wrap {
    width: 100%;
    height: 200px;
    background: transparent;
    /*overflow-y: auto;*/
    box-sizing: border-box;
    overflow-y: auto;
}

.schedule-modal-schedule-by-week-wrap::-webkit-scrollbar-track {
    background-color: transparent;
}

.schedule-modal-schedule-by-week-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.schedule-modal-schedule-by-week-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.schedule-modal-schedule-by-week-ct {
    width: 100%;
    height: 100%;
}

.schedule-modal-schedule-by-week-ct-ele {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 1px solid #bababa;
    border-top: none;
    box-sizing: border-box;
}

.schedule-modal-schedule-by-week-ct-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    width: 100px;
}

.schedule-index {
    width: 50px;
}

.schedule-open-time {
    width: 160px;
}

.schedule-open-time-all {
    width: 330px;
}

.schedule-appoint {
    width: 78px;
}

.add-icon {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
}

.add-icon:hover {
    font-size: 14px;
    color: rgba(19, 44, 140, 0.8);
    cursor: pointer;
}

.delete-icon {
    font-size: 14px;
    color: rgba(159, 159, 159, 0.8);
    cursor: pointer;
}

.delete-icon:hover {
    font-size: 14px;
    color: rgba(253, 50, 50, 0.8);
    cursor: pointer;
}

.no-schedule {
    margin: 0 0;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: bolder;
    color: #2168e0;
    border: 1px solid #bababa;
    border-top: none;
    box-sizing: border-box;
}

.schedule-modal-close {
    z-index: 91;
    position: absolute;
    top: calc(50% - 250px);
    left:  calc(50% + 410px);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.schedule-modify-modal-ct {
    z-index: 91;
    position: absolute;
    top: calc(50% - 190px);
    left: calc(50% - 300px);
    /*margin: 0 auto;*/
    padding: 20px 30px;
    width: calc(600px - 60px);
    height: calc(380px - 40px);
    background: white;
    overflow-y: auto;
}

.schedule-modify-modal-add-wrap {
    margin: 0 0 30px 0;
    width: 100%;
    height: auto;
}

.schedule-modify-modal-add-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: calc(100% - 2px);
    height: 50px;
    background: rgba(187, 187, 187, 0.3);
    border: 1px solid #bababa;
}

.schedule-modify-modal-add {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 50px;
    background: rgba(203, 203, 203, 0.3);
}

.schedule-modify-modal-add-ele {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    width: auto;
    font-size: 14px;
}

.schedule-modify-date-cell {
    width: 80px;
    height: 60%;
    /*min-width: 70px;*/
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    padding: 3px 2px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.schedule-modify-date-cell:hover {
    border: 1px solid #8391a5;
}

.schedule-modify-date-cell:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.schedule-modify-date-select-cell {

}

.schedule-modify-modal-add-week {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    /*margin-top: 10px;*/
    padding: 10px 10px;
    width: auto;
    height: 60px;
    background: rgba(203, 203, 203, 0.3);
}

.schedule-modify-modal-add-week-title {
    padding: 10px 8px 0;
    width: calc(100% - 16px);
    font-size: 14px;
    font-weight: bold;
    color: #1a55bb;
    border-top: 1px dashed black;
}

.schedule-modify-modal-add-week-ct {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 40px;
    background: transparent;
}

.select-week-cell {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 20%;
    height: 100%;
    font-size: 12px;
}

.schedule-modify-modal-close {
    z-index: 91;
    position: absolute;
    top: calc(50% - 150px);
    left:  calc(50% + 310px);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.schedule-cert-modal-ct {
    z-index: 91;
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 250px);
    /*margin: 0 auto;*/
    padding: 20px 30px;
    width: calc(500px - 60px);
    height: calc(300px - 40px);
    background: white;
    overflow-y: auto;
}

.schedule-cert-modal-cert-wrap {
    margin: 0;
    width: 100%;
    height: auto;
}

.schedule-cert-modal-cert {
    width: 100%;
    height: auto;
}

.schedule-cert-modal-cert-phone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 20px;
    width: 80%;
    height: 50px;
}

.schedule-cert-modal-cert-phone-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 40%;
    height: 80%;
    white-space: nowrap;
    font-size: 14px;
    color: #909399;
    background-color: #f5f7fa;
    border: 1px solid #dcdfe6;
    border-right: none;
    border-radius: 4px 0 0 4px;
}

.schedule-cert-modal-cert-phone-num {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 60%;
    height: 80%;
    white-space: nowrap;
    font-size: 14px;
    color: #909399;
    background-color: #f5f7fa;
    border: 1px solid #dcdfe6;
    border-radius: 0 4px 4px 0;
}

.schedule-cert-modal-cert-get {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 20px;
    width: 80%;
    height: 50px;
}

.cert-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    width: 90px;
    height: 30px;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #606266;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
}

.cert-button:hover {
    color: #00a1d6;
    border: 1px solid #00a1d6;
}

.schedule-cert-modal-close {
    z-index: 91;
    position: absolute;
    top: calc(50% - 150px);
    left:  calc(50% + 260px);
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* 专家简历 */
.expert-fun-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    box-sizing: border-box;
    overflow-y: auto;
}

.expert-resume-wrap {
    margin: 20px auto 0;
    width: 100%;
    height: 80%;
    background: transparent;
}

.expert-resume-ct {
    width: 100%;
    height: auto;
}

.expert-resume-ele {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    min-height: 50px;
}

.pic-ele-style {
    align-items: flex-start;
    height: auto;
}

.expert-resume-txt {
    text-align: right;
    padding: 0 15px;
    width: 100px;
    font-size: 14px;
    line-height: 38px;
}

.expert-resume-pic {
    width: 100px;
    height: auto;
}

.expert-resume-empty-ct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 100%;
}

/* 充值湃通币 */
.ptc-plugin {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 450px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.ptc-plugin-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 22px 30px;
    background: url("../../assets/img/rank_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 2px;
    color: #ffffff;
}

.ptc-plugin-ct-wrap {
    padding: 20px 30px;
    width: 100%;
    /*height: calc(100% - 200px);*/
    box-sizing: border-box;
}

.ptc-plugin-ct {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
}

.ptc-pay-item-wrap {
    padding: 10px 10px;
    width: calc(100% / 3);
    height: 100px;
    box-sizing: border-box;
}

.ptc-pay-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e7ecf1;
    color: black;
    cursor: pointer;
}

.item-selected {
    border: 1px solid #02a7e3;
    color: #02a7e3;
}

.ptc-pay-item-num {
    display: flex;
    align-items: flex-end;
}

.ptc-pay-item-num-num {
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
}

.ptc-pay-item-num-txt {
    margin-left: 10px;
    font-size: 14px;
    color: #282828;
    text-align: center;
}

.ptc-pay-item-price {
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #757575;
}

.ptc-diy-item-wrap {
    padding: 10px 10px;
    width: calc(100% / 3 * 2);
    height: 100px;
    box-sizing: border-box;
}

.ptc-diy-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e7ecf1;
    box-sizing: border-box;
    cursor: pointer;
}

.ptc-diy-item-selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #02a7e3;
    box-sizing: border-box;
    color: #02a7e3;
    cursor: pointer;
}

.ptc-diy-item-txt {
    font-size: 14px;
    color: #999;
}

.ptc-diy-ct {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}

.ptc-diy-input-wrap {
    display: flex;
    flex-direction: column;
}

.ptc-diy-input {
    width: 180px;
    height: 30px;
    border: 0;
    background-color: #f1f1f1;
    font-size: 14px;
}

.ptc-diy-input:focus {
    border: none;
    outline: none;
    background-color: #f1f1f1;
    font-size: 16px;
}

.ptc-diy-input:hover {
    border: 0;
}

.ptc-diy-input-price {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.ptc-diy-input-price-num {
    height: 30px;
    font-weight: bold;
    font-size: 24px;
}

.ptc-diy-input-price-txt {
    margin-top: 5px;
    font-size: 14px;
    color: #282828;
}

/* vip modal */
.pay-plugin {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
    font-family: PingFangSC-Regular;
}

.pay-plugin-box {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 450px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.pay-plugin-header-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 22px 30px;
    background: url("../../assets/img/rank_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
}

.pay-plugin-header-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    overflow: hidden;
}

.pay-plugin-header-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
    height: 90%;
}

.pay-plugin-header-name {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
}

.pay-plugin-header-info {
    opacity: 0.6;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
}

.pay-plugin-tab-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}

.pay-plugin-tab-item {
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #00a1d6;
}

.pay-plugin-price-list-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: auto;
}

.pay-plugin-price-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 100px;
    background: #ffffff;
    padding: 16px 0;
    border: 1px solid #ccd0d7;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
}

.pay-plugin-price-item-active {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 100px;
    background: #ffffff;
    padding: 16px 0;
    border: 2px solid #00a1d6;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
}

.pay-plugin-price-name {
    width: 100px;
    font-size: 14px;
    color: #282828;
    text-align: center;
}

.pay-plugin-price-num {
    margin-top: 8px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    font-size: 24px;
    color: #00a1d6;
    text-align: center;
}

.pay-plugin-deduction-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px auto 0;
    width: 90%;
    height: 50px;
    font-size: 14px;
}

.pay-plugin-deduction-txt {
    color: #282828;
}

.pay-plugin-deduction-check {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 15px;
    width: auto;
    height: 100%;
}

.pay-plugin-pay-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0;
    width: 90%;
    height: 100px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e5e9ef;
    box-sizing: border-box;
}

.pay-plugin-qr-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px auto 0;
    width: 90%;
    height: 150px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e5e9ef;
    box-sizing: border-box;
}

.pay-plugin-qr-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
    width: 110px;
    height: 100%;
    border-right: 1px solid #e5e9ef;
    overflow: hidden;
}

.pay-plugin-pay-way {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #e5e9ef;
    font-size: 12px;
    color: #282828;
    cursor: pointer;
}

.pay-plugin-pay-way-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #e5e9ef;
    font-size: 12px;
    font-weight: bold;
    color: #02132a;
    cursor: pointer;
}

.pay-plugin-qr-right {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% - 110px);
    height: 100%;
}

.pay-plugin-qr {
    width: 100px;
    height: 100px;
}

.pay-plugin-pay-num {
    margin-bottom: 8px;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    font-size: 28px;
    color: #00a1d6;
    letter-spacing: 0;
}

.pay-plugin-pay-btn {
    padding: 0 20px;
    width: auto;
    height: 30px;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    border: 0;
    background-color: #00a1d6;
    cursor: pointer;
}

.pay-plugin-qr-num {
    margin-bottom: 8px;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    font-size: 28px;
    color: #00a1d6;
    letter-spacing: 0;
}

.pay-plugin-qr-txt {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #6d757a;
}

.pay-plugin-rule-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 50px;
    font-size: 12px;
    color: #99a2aa;
}

.pay-plugin-rule-check {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    height: 100%;
}

.pay-plugin-rule-txt {
    margin-left: 8px;
    cursor: pointer;
}

.modal-close-wrap {
    z-index: 10;
    position: absolute;
    top: 20px;
    right: 30px;
    /*width: 14px;*/
    /*height: 14px;*/
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    color: rgb(255, 255, 255);
}

.modal-close-wrap:hover {
    transform: rotate(180deg);
}

.modal-plugin {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(159, 159, 159, 0.5);
}

.form-plugin-box {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 700px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    /*overflow-y: auto;*/
}

.form-plugin-header-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 22px 30px;
    background: url("../../assets/img/rank_header.jpg") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    color: white;
}

.form-plugin-paper-wrap {
    margin: 20px auto 30px;
    width: 95%;
    height: 700px;
    overflow-y: auto;
}

.form-plugin-paper-wrap::-webkit-scrollbar-track {
    /*border-radius:10px;*/
    background-color: transparent;
}

.form-plugin-paper-wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

.form-plugin-paper-wrap::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(123, 150, 165, 0.9);
    background-color: transparent;
}

.form-plugin-paper-ct {
    position: relative;
    width: 100%;
    height: 100%;
}

.form-plugin-paper-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
}

.form-plugin-paper-item-pic {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 211px;
}

.form-plugin-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.close-item-plugin {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 220px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}

.close-item-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px auto;
    width: 90%;
    height: 80px;
    /*background-color: #1a55bb;*/
}

.operation-plugin {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5px;
    width: 70px;
    height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
}

/*.operation-plugin-icon {*/
/*    font-size: 14px;*/
/*}*/

/*.operation-plugin-txt {*/
/*    font-size: 14px;*/
/*}*/

.add-fun {
    border: 1px solid rgb(143, 195, 248);
    color: rgb(24, 144, 255);
}

.add-fun:hover {
    font-size: 16px;
    border: 0;
    background-color: rgb(24, 144, 255);
    color: rgb(255, 255, 255);
}

.delete-fun {
    width: 100%;
    /*border: 1px solid rgb(255, 0, 0);*/
    color: rgb(235, 76, 68);
    font-size: 12px;
    font-weight: bold;
    background: white;
    letter-spacing: 1px;
    border-radius: 5px;
}

.delete-fun:hover {
    border: 0;
    background-color: rgb(235, 76, 68);
    color: rgb(255, 255, 255);
}

.login-plugin {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 360px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
}

.login-plugin-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 80px;
}

.login-plugin-input {
    padding: 3px 10px;
    width: 60%;
    height: 40px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 4px;
    border: 1px solid #bfcbd9;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
}

.login-plugin-input::placeholder {
    font-size: 14px;
    letter-spacing: 1px;
}

.login-plugin-input:hover {
    border: 1px solid #8391a5;
}

.login-plugin-input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
}

.login-plugin-pwd-eye-out {
    position: absolute;;
    transform: translate(120px, 0);
    font-size: 18px;
    cursor: pointer;
}

.login-plugin-send-code-get {
    position: absolute;
    background-color: transparent;
    border: none;
    font-size: 12px;
    color: #3399ea;
    line-height: 24px;
    letter-spacing: 1px;
    transform: translate(90px, 0);
    cursor: pointer;
}

.login-plugin-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    height: 70px;
    /*background-color: #2066b5;*/
}

.login-plugin-way-wrap {
    margin: 0 auto;
    width: 90%;
    height: 110px;
}

.login-plugin-way-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background: transparent;
}

.login-plugin-way-line {
    width: 35%;
    height: 1px;
    background: #e3e3e3;
}

.login-plugin-way-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 48px;
    background: transparent;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    color: #a0a0a0;
}


.login-plugin-way-ct {
    width: 100%;
    height: 48px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-plugin-qr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 70%;
    height: 150px;
}

.login-plugin-qr-left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 150px;
    height: 80%;
}

.login-plugin-qr-right {
    width: 140px;
    height: 140px;
}

/* notice-mgr */
.notices-mgr-ct {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0 0 8px 0 rgb(0, 0, 0, 5%);
    box-sizing: border-box;
}

.notices-mgr-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 0;
    margin-top: 20px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: #1c68c2;
}

.notices-mgr-header-cell {
    display: flex;
    align-items: center;
    /*margin: 0 20px;*/
    width: auto;
    height: 70%;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #ffffff;
    /*background-color: #9fccff;*/
    box-sizing: border-box;
}

.notice-check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.notice-checkbox {
    margin: 0;
}

.checkbox-minus {
    position: absolute;
    font-size: 12px;
    transform: scale(0.7) translate(1px, 0);
    color: black;
}

.notice-status{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8%;
    font-size: 16px !important;
    color: #1e3c72 !important;
    border-left: 1px solid #c1c8d2;
}

.notice-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 15%;
    border-left: 1px solid #c1c8d2;
}

.notice-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 15%;
    border-left: 1px solid #c1c8d2;
}

.notice-ct {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 63%;
    border-left: 1px solid #c1c8d2;
}

.notice-txt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    padding-right: 20px;
    width: 63%;
    box-sizing: border-box;
    border-left: 1px solid #c1c8d2;
}

.notice-txt-style {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notices-mgr-header-fun {
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    width: 100px;
    cursor: pointer;
}

.notices-mgr-notices-list {
    margin-top: 20px;
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
}

.notice-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px 0 0;
    width: 100%;
    height: 50px;
    border: 1px solid #8391a5;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
}

.notice-list:hover {
    background-color: rgba(222, 222, 222, 0.8);
}

.notice-cell {
    /*margin: 0 20px;*/
    height: 65%;
    font-size: 12px;
    letter-spacing: 2px;
    color: #6d6d6d;
    /*background-color: #9fccff;*/
    box-sizing: border-box;
}

.notice-cell-main-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
}

.notice-plugin-box {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 400px;
    transform: translate(-50%, -50%);
    background-color: rgb(246, 246, 246);
    border-radius: 4px;
    overflow: hidden;
}

.notice-plugin-info {
    margin: 20px auto 20px;
    width: 90%;
    height: 210px;
}

.notice-plugin-info-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 80px;
    background-color: white;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 4%);
}

.notice-plugin-info-header-left {
    width: 20%;
    height: 80%;
}

.notice-plugin-info-header-right {
    width: 75%;
    height: 80%;
}

.notice-plugin-info-header-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
}

.notice-plugin-info-header-item-title {
    width: 100px;
    font-size: 14px;
    line-height: 30px;
}

.notice-plugin-info-header-item-txt {
    width: auto;
    font-size: 14px;
    line-height: 30px;
}

.notice-plugin-info-main {
    padding: 15px 15px;
    width: 100%;
    height: 110px;
    background-color: white;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 4%);
    box-sizing: border-box;
}

.notice-plugin-info-main-msg {
    width: 100%;
    height: 100%;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 25px;
    word-break: break-all;
}

.notice-plugin-no-data {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    box-sizing: border-box;
}

/* antd select */
.ant-select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* margin: 0; */
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
}

.ant-select-show-search .ant-select-selector{
    width: 200px;
    min-height: 28px;
    border-radius: 6px;
}

.ant-select-show-search .ant-select-selector .ant-select-selection-item{
    height: 20px;
    line-height: 20px;
    border-radius: 6px;
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.ant-switch-checked {
    background: #1890ff;
}

.ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all .2s ease-in-out;
}

.ant-switch-handle:before {
    position: absolute;
    inset: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px #00230b33;
    transition: all .2s ease-in-out;
    content: "";
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px);
}
