.about-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    min-width: 1200px;
    width: 100%;
    max-width: 2000px;
    /*background: grey;*/
    background-image: url("../../assets/about/bk.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.about-ct {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 1200px;
}

.about-banner-outer {
    min-width: 960px;
    max-width: 1280px;
    width: 59%;
    display: flex;
    align-items: center;
}

.about-banner-words-outer {
    width: calc(100% - 400px);
    height: 100%;
    font-size: 50px;
    font-weight: bold;
    font-family: 黑体, serif;
    color: white;
    letter-spacing: 4px;
}

.about-banner-img {
    z-index: 0;
    /*width: 100%;*/
    height: 300px;
    /*min-width: 1200px;*/
    background: transparent;
    object-fit: cover;
    overflow: hidden;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.about-page-ct {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74%;
    min-height: 1060px;
    background: white;
    min-width: 1200px;
    max-width: 1600px;
}

.about-page-tags {
    width: 80%;
    height: 60px;
    background: white;

    border-bottom: 1px solid gray;
}

.about-tags-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 70%;
    height: 100%;
}

.about-tag-ct {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
    background: transparent;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
}

.about-tag-ct:hover {
    color: #326bb1;
    cursor: pointer;
}

.about-tag-ct-sel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
    background: transparent;
    font-size: 18px;
    font-weight: bolder;
    color: #000000;
    cursor: pointer;
}

.about-page-tags-ct {
    width: 100%;
}

.about-tags-ct-wrap {
    margin: 0 auto;
    width: 80%;
    height: 100%;
}

.about-com-ct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0;
    width: 100%;
    height: 100%;
}

.about-com-ct-level-one {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 300px;
    /*background: #61dafb;*/
}

.about-com-ct-level-two {
    width: 100%;
    min-height: 500px;
    /*background: #595959;*/
}

.about-com-ct-level-three {
    width: 100%;
    /*background: #e3e3e3;*/
}

.about-com-ct-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    /*background: #eeeeee;*/
    font-size: 24px;
    font-weight: bolder;
    color: black;
}

.partner-name-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 12px;
    /*font-weight: bolder;*/
    color: black;
}

.about-com-ct-content {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    margin-top: 10px;
    width: 100%;
    height: 80%;
    /*background: #eeeeee;*/
    text-indent: calc(2em + 4px);
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 40px;
}

.about-com-ct-board {
    display: flex;
    align-items: center;
    margin: 10px auto 20px;
    width: 100%;
    height: 120px;
    /*background: #969696;*/
}

.about-com-ct-board-ot {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 80%;
    /*background: #dedede;*/
}

.about-com-ct-board-ct {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
    height: 50%;
}

.board-num {
    width: 45%;
    font-size: 40px;
    font-weight: bolder;
    color: #1a55bb;
}

.board-title {
    font-size: 20px;
    transform: translateY(18px);
    /*font-weight: bolder;*/
}

.about-com-ct-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    width: 100%;
    height: auto;
    /*background: #3c92cc;*/
}

.about-com-ct-team-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    margin-top: 20px;
    width: 100%;
    height: 1100px;
    /*background: #eeeeee;*/
}

.about-com-ct-team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 46%;
    height: 300px;
    /*background: #e05151;*/
}

.about-com-ct-team-img {
    width: 25%;
    height: 40%;
    /*background: #a46666;*/
}

.about-com-ct-team-info {
    width: 70%;
    height: 100%;
    /*background: #a9a3a3;*/
}

.about-com-ct-team-info-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.about-com-ct-team-info-name {
    font-size: 22px;
    font-weight: bolder;
    color: #1a55bb;
}

.about-com-ct-team-info-title {
    margin-left: 10px;
    font-size: 16px;
    padding-bottom: 2px;
}

.about-com-ct-team-info-ct {
    padding-top: 10px;
    font-size: 16px;
    line-height: 30px;
    white-space: break-spaces;
}

.about-com-ct-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px auto 0;
    width: 100%;
    height: auto;
    background: transparent;
}

.about-com-ct-layout-ele {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
    width: calc(100% / 8);
    height: 130px;
    /*background: #1a55bb;*/
}

.partner-ele {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.partner-img {
    width: 120px;
    height: 120px;
}

.about-com-ct-content-other {
    text-align: center;
    width: 100%;
    height: 100%;
    /*background: #eeeeee;*/
    font-size: 20px;
    line-height: 40px;
}

.about-culture-ct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
    /*background: #61dafb;*/
}

.about-culture-ct-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    /*background: #eeeeee;*/
    font-size: 24px;
    font-weight: bolder;
    color: black;
}

.about-culture-ct-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 200px;
    font-size: 16px;
    line-height: 30px;
}

.about-culture-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 28%;
    height: 100%;
}

.about-culture-icon {
    width: 100px;
    height: 100px;
    border: 0;
    border-radius: 50%;
    overflow: hidden;
}

.about-culture-title {
    font-size: 18px;
    /*font-weight: bold;*/
    height: 40px;
    background: transparent;
    display: flex;
    align-items: center;
}

.about-culture-content {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 30px;
    height: 20%;
}



.about-join-level {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 300px;
    /*background: #61dafb;*/
}

.about-join-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 80%;
    font-size: 16px;
    line-height: 30px;
}

.about-join-content {
    width: 60%;
    height: 100%;
    text-indent: 2em;
}

.about-join-res {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.about-join-wrap-ct {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 100%;
    height: 400px;
    background: #e8e8e8;
}

.about-join-layout {
    padding: 40px;
    width: auto;
    height: 100%;
}

.about-join-icon {
    margin: 0 0 40px 0;
    width: 50px;
    height: 50px;
    background: white;
}

.about-join-title {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: bolder;
    color: black;
}

.about-join-ct {
    font-size: 14px;
    color: gray;
}

.img-style {
    width: 100%;
    height: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

