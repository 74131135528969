.out-content {
    width: 100%;
    min-width: 1200px;
}

.home-ct {
    /*font-family: 'Times New Roman',Times, '宋体', serif;*/
    font-family: pingfang SC, helvetica neue, arial, hiragino sans gb, microsoft yahei ui, microsoft yahei, simsun, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.home-map-img {
    width: 50%;
    min-width: 1200px;
    max-height: 470px;
    max-width: 1800px;
    object-fit: cover;
}

.home-map {
    position: absolute;
    width: 100%;
    min-width: 1200px;
    height: 470px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-ot {
    height: 160px;
    display: flex;
    flex-direction: column;
}

.header-top {
    width: 100%;
    height: 40px;
    background: #53657d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-bottom {
    width: 80%;
    /*width: calc(100% - 560px);*/
    /*width: 100%;*/
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border-bottom: 3px solid rgb(11, 101, 236);*/
}

.header-bottom-outer {
    width: calc(100% - 326px);
    background: white;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item-lst {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 72%;
    background: transparent;
}

.tech-item-column {
    height: 100%;
    width: 20%;
    background: transparent;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: bold;
    color: #316eb6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.expert-area-col {
    height: 100%;
    width: 30%;
    background: transparent;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 800;
    color: #82aee3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.expert-item-area-ot {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    background: transparent;
}

.expert-item-job-ot {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 28%;
    height: 100%;
    background: transparent;
}

.menu-item-area {
    width: 30%;
    height: 20%;
    background: transparent;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 800;
    color: #82aee3;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.menu-item-area:hover {
    color: #0070ff;
}

.menu-item-area-more {
    display: flex;
    align-items: center;
    width: 30%;
    height: 20%;
    color: #6d6d6d;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
}

.menu-item-area-more:hover {
    color: #0070ff;
}

.menu-item-job {
    width: 50%;
    height: 20%;
    background: transparent;
    color: #316eb6;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.menu-item-job:hover {
    color: #0070ff;
}

.menu-item-job-more {
    display: flex;
    align-items: center;
    width: 50%;
    height: 20%;
    color: #6d6d6d;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
}

.menu-item-job-more:hover {
    color: #0070ff;
}

.menu-item-place {
    width: 100%;
    height: 20%;
    background: transparent;
    color: #316eb6;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.menu-item-place:hover {
    color: #0070ff;
}

.menu-item-place-more {
    display: flex;
    align-items: center;
    width: 100%;
    height: 20%;
    color: #6d6d6d;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
}

.menu-item-place-more:hover {
    color: #0070ff;
}

.menu-item-name {
    width: 20%;
    height: 25%;
    background: transparent;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: bold;
    color: #316eb6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.menu-item-name:hover {
    color: #0070ff;
}

.menu-item-name-more {
    color: #6d6d6d;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.menu-item-name-more:hover {
    color: #0070ff;
}

.menu-more-info:hover {
    color: #0070ff;
}

.menu-more-info {
    width: 100%;
    height: 14%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #6d6d6d;
}

.solution-item {
    font-size: 14px;
    font-weight: bold;
    /*width: 6%;*/
    /*min-width: 100px;*/
    margin-right: 20px;
    color: #316eb6;
}

.solution-item:hover {
    color: #0070ff;
}

.title-sp {
    width: 4px;
    height: 22px;
    background: #0070ff;
    margin-right: 10px;
    border-radius: 14px;
}

.expert-chara {
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
}

.tech-header {
    width: 100%;
    height: 14%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: bold;
    color: #6d6d6d;
}

.menu-type-dwn {
    height: 24px;
    margin: 0;
    padding: 0 8px;
    width: 124px;
    border: 2px solid #d4dfec;
    cursor: pointer;
    background: #fafafa;
}

.menu-type-dwn:focus {
    margin: 0;
    /*padding: 0;*/
    outline: none;
}

.expert-item-ot {
    width: 50%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
}

.expert-item-place-ot {
    width: 14%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    color: #316eb6;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
}

/*.expert-item-job-ot {*/
/*    width: 14%;*/
/*    height: 100%;*/
/*    background: transparent;*/
/*    display: flex;*/
/*    justify-content: space-evenly;*/
/*    flex-direction: column;*/
/*    color: #316eb6;*/
/*    font-weight: bold;*/
/*    font-size: 16px;*/
/*    letter-spacing: 2px;*/
/*}*/

.menu-item-pg-ot {
    min-width: 1200px;
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-menu {
    min-width: 1200px;
    max-width: 1800px;
}

.solution-outer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.solution-menu-ct {
    height: 220px;
}

.solution-line {
    font-size: 18px;
    color: #6d6d6d;
    font-weight: bold;
    width: 100%;
    height: 55px;
    background: transparent;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
}

.menu-item-pg {
    width: 80%;
    height: 260px;
    min-width: 1200px;
    max-width: 1800px;
}

.header-bt-ct {
    width: 100%;
    min-width: 1000px;
    max-width: 1400px;
    height: 100%;
    background: #ffffff;
    display: flex;
}

.header-logo-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 26px;
    width: 300px;
    height: 80px;
    background: white;
}

.header-logo-top {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.sues-img {
    width: 59px;
    height: 70px;
}

.tusp-img {
    margin-bottom: 4px;
    width: 191px;
    height: 30px;
}

.header-logo {
    display: flex;
    align-items: center;
    justify-content: left;
    /*padding-left: 26px;*/
    /*padding-right: 26px;*/
    width: 191px;
    /*height: 100%;*/
    height: 35px;
    background: white;
    /*position: absolute;*/
    /*left: 0;*/
    /*transform: translateY(12px);*/
    /*background: #a3dbff;*/
    cursor: pointer;
}

.header-logo-img {
    /*width: 160px;*/
    width: 100%;
    height: 100%;
}

.header-topic {
    width: 60%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 4px;
    font-weight: 1000;
    color: #929292;
}

.header-slogan {
    height: 100%;
    /*width: 430px;*/
    background: transparent;
    display: flex;
}

.menu-item {
    height: 100%;
    width: 3%;
    min-width: 70px;
    background: transparent;
    font-size: 14px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2576d9;
    letter-spacing: 2px;
    cursor: pointer;
}

.menu-item:hover {
    color: #0070ff;
}

.menu-layout {
    width: 100%;
    position: fixed;
    background: transparent;
    cursor: pointer;
    z-index: 998;
}

.menu-ct {
    width: 100%;
    height: 3px;
    position: fixed;
    /*margin-top: -3px;*/
    /*background: #e8e8e8;*/
    cursor: pointer;
    /*border-top: 3px solid rgb(11, 101, 236);*/
}

.menu-ct-up {
    width: 100%;
    min-height: 300px;
    background: #f1f1f1;
    cursor: pointer;
    border-bottom: 3px solid #3c80d4;
    border-top: 3px solid rgb(206, 206, 206);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-menu {
    /*width: calc(100% - 430px);*/
    width: 100%;
    height: 100%;
    background: transparent;
    float: right;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-tp-ct {
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    height: 100%;
    background: #53657d;
    display: flex;
    justify-content: flex-end;
}

.header-target {
    height: 100%;
    width: calc(100% - 200px);
    background: transparent;
    display: flex;
    align-items: center;
    letter-spacing: 4px;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.header-op {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    width: auto;
    height: 100%;
    /*width: 220px;*/
}

.header-op-left {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: auto;
}

.header-op-right {
    display: flex;
    flex-direction: row;
    width: auto;
}

.header-item {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    width: auto;
    font-size: 14px;
    color: white;
    text-decoration: none;
}

.logout-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px 0 20px;
    font-size: 14px;
    color: white;
}

.search-outer {
    width: 100%;
    height: 100%;
}

.search-title {
    width: 100%;
    height: 40%;
    background: transparent;
    font-size: 30px;
    font-weight: bolder;
    letter-spacing: 6px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #0082e5;
}

.search-ct {
    width: 100%;
    height: 60%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-win {
    width: 70%;
    height: 60%;
    background: transparent;
    display: flex;
}

.search-item {
    height: 100%;
    width: 10%;
    background: #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #ffffff;
    cursor: pointer;
}

.search-item-txt {
    text-indent: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 16px;
    letter-spacing: 1px;
}

.search-input {
    height: 100%;
    width: 69%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input-ct:focus {
    border: 2px solid #a4bfdc;
    height: calc(100% - 4px);
    background: white;
    transition-property: width, background, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.search-input-ct {
    /*border: 2px solid #e0edf6;*/
    width: 96%;
    height: 100%;
    background: #f6f6f6;
    outline: none;
    border: 0;
    padding: 0 0 0 20px;
    font-size: 20px;
    color: #0073b1;
    letter-spacing: 2px;
    transition-property: width, background, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear, ease;
}

.search-bt {
    height: 100%;
    width: 10%;
    background: transparent;
    color: rgb(81, 142, 222);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: 32px;
    cursor: pointer;
}

.outcome-expert {
    height: 200px;
    width: 100%;
    background: #b9d1ea;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-ct {
    height: 800px;
    width: 100%;
}

.expert-ct-swipes {
    height: 600px;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.expert-line {
    width: 100%;
    height: 300px;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.expert-content {
    height: 200px;
    width: calc((100% - 280px) / 2);
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expert-title {
    height: 20%;
    background: #ececec;
    width: 86%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-name {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
}

.expert-jobs {
    margin-left: 10px;
    display: flex;
}

.expert-area {
    height: 100%;
    background: #ffffff;
    width: 86%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-size: 14px;
}

.expert-pos {
    width: 90%;
    height: 20%;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.expert-studies {
    width: 90%;
    height: 20%;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.expert-des {
    height: 40%;
    background: #ffffff;
    width: 86%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.expert-des-line {
    width: 90%;
    height: 50%;
    background: white;
    display: flex;
}

.expert-art {
    width: 50%;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.expert-space {
    height: 200px;
    width: calc((100% - 280px) / 2);
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 4px;
    flex-direction: column;
}

.expert-slogan {
    height: 40%;
    width: 80%;
    background: #ffffff;
    display: flex;
    align-items: center;
    line-height: 30px;
    font-size: 16px;
    justify-content: center;
}

.expert-selection {
    height: 46%;
    width: 60%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.expert-button {
    font-size: 14px;
    background: #ffffff;
    width: 98px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: rgb(95, 156, 229);
    border: 1px solid rgb(95, 156, 229);
    cursor: pointer;
}

.expert-button:hover {
    font-size: 14px;
    background: rgb(95, 156, 229);
    width: 98px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.expert-pic {
    height: 200px;
    width: 200px;
    background: #ffffff;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

html {
    scroll-padding-top: 70px;
}

.page-ct {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    height: 100%;
    background: transparent;
}

.traffic-ct {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 1200px;
    max-width: 2000px;
}

.traffic-bg-one {
    background: #4b6cb7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #273396, #472E94);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #273396, #472E94); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.traffic-bg-two {
    background: #160550;
    background: -webkit-linear-gradient(to right, #160550, #440F85);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #160550, #440F85); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.traffic-txt-wrap {
    position: absolute;
    top: 0;
    left: 200px;
    width: auto;
    height: 200px;
    background: transparent;
}

.traffic-ct-txt {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*margin: 0 auto;*/
    width: 100%;
    height: 100%;
    min-width: 960px;
    /*max-width: 1440px;*/
    font-size: 28px;
    font-weight: bolder;
    letter-spacing: 4px;
    line-height: 30px;
    color: white;
}

.traffic-txt {
    line-height: 45px;
}

.login-page {
    width: 1000px;
    height: 540px;
    background: white;
    /*box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);*/
    display: flex;
}

.func-ad {
    height: 100%;
    width: 50%;
    background: #3775c1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.func-ad-ct {
    width: 74%;
    height: 86%;
    background: transparent;
}

.func-logo {
    height: 50px;
    width: 150px;
    /*background: rgba(255, 255, 255, 0.3);*/
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
}

.func-ad-logo-level {
    width: 100%;
    height: 70px;
    background: transparent;
    display: flex;
    align-items: center;
}

.func-ad-title-level {
    width: 100%;
    height: 80px;
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: normal;
    letter-spacing: 4px;
    color: white;
}

.func-ad-des-level {
    width: 100%;
    height: 156px;
    background: transparent;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    line-height: 36px;
    letter-spacing: 2px;
    font-size: 16px;
    color: #f3f3f3;
}

.func-ad-img {
    width: 500px;
    position: absolute;
}

.func-ct {
    height: 100%;
    width: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.func-ct-outer {
    width: 76%;
    height: 84%;
    background: transparent;
}

.change-pass-title-outer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #5c5c5c;
    font-weight: bold;
    letter-spacing: 2px;
}

.func-reg-ct-outer {
    width: 76%;
    height: 84%;
    /*height: 96%;*/
    background: transparent;
}

.func-ct-title {
    width: 100%;
    height: 50px;
    background: transparent;
    display: flex;
}

.func-title-name {
    width: 50%;
    height: 100%;
    background: transparent;
    font-size: 24px;
    color: #5c5c5c;
    font-weight: bold;
    letter-spacing: 2px;
    display: flex;
    align-items: flex-end;
}

.func-title-corner {
    width: 50%;
    height: 100%;
    background: transparent;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.func-ct-input-outer {
    height: 170px;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.func-reg-ct-input-outer {
    height: 320px;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.other-func {
    height: 30px;
    width: 100%;
    background: transparent;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: rgb(33,115,199);
}

.func-main-bt {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    text-indent: 4px;
    font-size: 18px;
    color: white;
    background: #3c80d4;
    margin-top: 10px;
    cursor: pointer;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.func-main-bt:hover {
    background: #518cd5;
    transition-property: background;
    -moz-transition-property: background; /* Firefox 4 */
    -webkit-transition-property:background; /* Safari and Chrome */
    -o-transition-property:background; /* Opera */
    transition-duration: 100ms;
    -moz-transition-duration: 100ms;
    -webkit-transition-duration: 100ms;
    -o-transition-duration: 100ms;
    transition-timing-function: linear, ease;
    -moz-transition-timing-function: linear, ease;
    -webkit-transition-timing-function: linear, ease;
    -o-transition-timing-function: linear, ease;
}

.func-center-helper {
    height: 50px;
    width: 100%;
    background: transparent;
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(33,115,199);
    cursor: pointer;
}

.item-flow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-flow-des {
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 1px;
}

.func-login-method {
    height: 50px;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.func-style-line {
    height: 2px;
    width: 130px;
    background: #e3e3e3;
}

.login-other-methods {
    z-index: 999;
    width: 100%;
    height: 48px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oauth-btn {
    margin: 0 20px;
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.login-method-des {
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    color: #a0a0a0;
}

.func-input-notice {
    font-size: 12px;
    color: red;
    height: 28px;
    letter-spacing: 2px;
    margin-top: 2px;
}

.func-reg-input-notice {
    font-size: 12px;
    color: red;
    height: 22px;
    letter-spacing: 2px;
    margin-top: 2px;
}

.reg-notice-checkbox {
    width: 100%;
    height: 38px;
    padding: 0 0 12px 0;
    background: transparent;
    font-size: 14px;
}

.func-ct-input-valid {
    width: 100%;
    height: 74px;
    background: transparent;
}

.func-ct-input-valid-num {
    width: calc(48% - 34px);
    height: 40px;
    padding-left: 34px;
    font-size: 16px;
    margin: 0;
    border-top: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    border-left: 0;
}

.func-ct-valid-line {
    height: 44px;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.send-valid-bt {
    font-size: 14px;
    letter-spacing: 2px;
    text-indent: 2px;
    width: 118px;
    height: 42px;
    border: 1px solid #8aaed7;
    color: #8aaed7;
    background: #ffffff;
    margin-left: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.send-valid-sign {
    font-size: 20px;
    color: #f1f1f1;
    width: 44px;
    height: 44px;
    background: #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
}

.func-ct-input-valid-num:focus {
    padding-left: 34px;
    margin: 0;
    border-top: 1px solid #8aaed7;
    border-right: 1px solid #8aaed7;
    border-bottom: 1px solid #8aaed7;
    outline: none;
    border-left: 0;
}

.func-ct-input-reg {
    width: calc(100% - 14px);
    height: 34px;
    padding-left: 10px;
    font-size: 16px;
    margin: 0;
    border: 1px solid #d7d7d7;
}

.func-ct-input-reg:focus {
    padding-left: 10px;
    margin: 0;
    border: 1px solid #8aaed7;
    outline: none;
}

.func-ct-input {
    width: calc(100% - 14px);
    height: 40px;
    padding-left: 10px;
    font-size: 16px;
    margin: 0;
    border: 1px solid #d7d7d7;
}

.func-ct-input:focus {
    padding-left: 10px;
    margin: 0;
    border: 1px solid #8aaed7;
    outline: none;
}

.func-pg-bk {
    height: 100vh;
    min-height: 670px;
    width: 100%;
    min-width: 1200px;
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-pass-win {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-win-inner {
    width: 500px;
    height: 540px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-header-title {
    width: 100%;
    height: 180px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.policy-info-ct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 500px;
    background: transparent;
}

.policy-sp {
    height: 480px;
    width: calc(40% - 2px);
    position: absolute;
    border-right: 4px solid #eaeaea;
    z-index: 1;
}

.policy-line {
    margin-bottom: calc(80px / 5);
    width: 100%;
    height: 70px;
    background: transparent;
    display: flex;
    justify-content: center;
    z-index: 2;
}

.policy-time {
    width: calc(40% - 35px);
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 2px;
}

.policy-time-tag {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
}

.policy-tag-ct {
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000000;
    margin-right: 26px;
    letter-spacing: 4px;
    /*cursor: pointer;*/
}

.policy-tag-ct:hover {
    /*font-weight: bold;*/
}

.policy-tag-time {
    width: 100%;
    height: 100%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2px;
    color: #4571a7;
    margin-right: 20px;
}

.policy-pos {
    width: 70px;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.policy-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: #1859DE;
    border-radius: 50%;
    font-size: 22px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.policy-tag:hover {
    background: rgb(82, 135, 199);
    color: white;
}

.policy-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(60% - 35px);
    height: 100%;
    background: transparent;
}

.policy-name-txt {
    margin-left: 30px;
    width: auto;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 24px;
    cursor: pointer;

}

.policy-name-txt:hover {
    color: rgb(60, 128, 212);
}

.info-header-name {
    width: 220px;
    height: 22%;
    background: transparent;
    letter-spacing: 4px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid rgb(60, 128, 212);
}

.info-header-des {
    width: 220px;
    height: 22%;
    background: transparent;
    letter-spacing: 4px;
    color: #909090;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.static-ct {
    width: 100%;
    height: 80%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-content {
    max-width: 1500px;
    width: 80%;
    height: 80%;
    background: transparent;
}

.data-level {
    width: 100%;
    height: 50%;
    background: transparent;
    display: flex;
}

.data-item {
    width: calc(50% - 170px);
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.data-title {
    width: 100%;
    height: 30%;
    background: transparent;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 2px;
    color: rgb(81, 142, 222);
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-num {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 40%;
    background: transparent;
}

@media (prefers-reduced-motion: no-preference) {
    .data-bk {
        animation: data-bk-spin infinite 4s linear;
    }

    .data-bk-c {
        animation: data-bk-spin-c infinite 30s linear;
    }
}

@keyframes data-bk-spin-c {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes data-bk-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.roll-num {
    display: flex;
    font-family: D-DIN, sans-serif;
    font-size: 44px;
    line-height: 56px;
    height: 56px;
    vertical-align: middle;
    color: rgb(94, 94, 94);
}

.data-unit {
    margin-left: 15px;
    padding-bottom: 7px;
    font-size: 24px;
}

.data-bk {
    position: absolute;
    width: 340px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.data-bk-c {
    position: absolute;
    width: 340px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.tags-outer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.tag-ct {
    height: 90%;
    width: 22%;
    background: transparent;
    color: #a3b6cd;
}

.tag-ct:hover {
    color: #326bb1;
    cursor: pointer;
}


.tag-title {
    width: 100%;
    height: 26%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-weight: bolder;
    color: #326bb1;
}

.tag-des {
    width: 100%;
    height: 42%;
    background: transparent;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 350px;
    margin: auto;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 26px;
    color: #747474;
}

.tag-sign {
    width: 100%;
    height: 32%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.tag-sp {
    height: 70%;
    width: 1px;
    background: #bdbdbd;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tag-sp-in {
    height: 4%;
    width: 1px;
    background: rgb(215, 215, 215);
}

.page-header {
    text-align: center;
    margin: 20px 0 40px;
    font-size: 28px;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.7);
}

.page-more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*margin: 0 auto 40px;*/
    width: auto;
    color: #666666;
}

.page-more:hover {
    color: rgba(24, 89, 222, 0.7)
}

.page-more-text {
    font-size: 14px;
    cursor: pointer;
}

.page-more-icon {
    margin-left: 2px;
    font-size: 14px;
    cursor: pointer;
}

.banner-ct {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.banner-up-ct {
    width: 100%;
    height: 72%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-img-wrap {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 72%;
}

.banner-img {
    width: 60%;
    max-width: 900px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.banner-img-other {
    width: 60%;
    max-width: 900px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    background-color: rgba(255, 255, 255, 0.5);
}

.banner-dn-ct {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 28%;
    background: transparent;
}

.banner-selection {
    height: 100%;
    width: 60%;
    background: transparent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.banner-button {
    width: 180px;
    height: 42px;
    display: flex;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-weight: bolder;
    cursor: pointer;
}

.home-banner-ot {
    height: 500px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-banner-img {
    position: absolute;
    height: 500px;
    width: 100%;
    min-width: 1200px;
    max-width: 2000px;
    object-fit: cover;
}

.tag-ot {
    height: 180px;
    background: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stat-ot {
    height: 500px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outcome-title {
    height: 120px;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: flex-end;
}

.outcome-title-card {
    height: 70px;
    width: 100%;
    background: transparent;
    display: flex;
}

.demand-ct {
    width: 100%;
    min-height: 600px;
    /*height: 600px;*/
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.demand-enter {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 40px;
    width: 100%;
    height: 80px;
    background: transparent;
}

.expert-join {
    width: 360px;
    height: 40px;
    border: 1px solid rgb(25, 109, 212);
    background: transparent;
    display: flex;
    letter-spacing: 4px;
    color: rgb(25, 109, 212);
    cursor: pointer;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.expert-join:hover {
    background: rgb(33, 114, 213);
    color: rgb(255, 255, 255);
}

.demand-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    /*height: 80%;*/
    background: transparent;
}

.demand-item {
    width: 32%;
    /*min-width: 396px;*/
    /*background: rgb(82, 135, 199);*/
    /*height: 45%;*/
    height: 216px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(16, 58, 104, 0.2);
    background: #1488CC;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3b72b0, #1488CC);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3b72b0, #1488CC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.demand-item:hover {
    transform: translate(0, -5px);
    animation: demand-item-amin 100ms 1 linear;
    -webkit-animation: demand-item-amin 100ms 1 linear;
    box-shadow: 0 5px 10px rgba(16, 58, 104, 0.3);
}

@keyframes demand-item-amin  {
    0% {
        transform: translate(0, 0);
        box-shadow: 0 0 10px rgba(16, 58, 104, 0.2);
    }

    100% {
        transform: translate(0, -5px);
        box-shadow: 0 5px 10px rgba(16, 58, 104, 0.3);
    }
}

.demand-title {
    text-align: center;
    height: 30%;
    width: 84%;
    background: transparent;
    color: rgb(89, 89, 89);
    line-height: calc(216px * 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: 2px;
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    box-sizing: border-box;
}

.demand-title-ot {
    height: 30%;
    width: 100%;
    background-image: url("../assets/img/demand_bg.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
}

.demand-info {
    height: 44%;
    width: 84%;
    background: transparent;
}

.demand-info-line {
    width: 100%;
    height: 50%;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2px;
}

.info-item {
    width: 50%;
    height: 100%;
    line-height: calc(216px * 0.22);
    background: transparent;
    display: flex;
    align-items: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    box-sizing: border-box;
}

.info-item-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.demand-bt {
    height: 26%;
    width: 84%;
    background: transparent;
    display: flex;
    font-size: 14px;
    color: white;
}

.solution-title-card {
    height: 36px;
    width: 100%;
    background: #ffffff;
    display: flex;
}

.solution-slide-page {
    width: 100%;
    /*height: 630px;*/
    height: auto;
    background: #ffffff;
}

.solution-selection {
    cursor: pointer;
    padding-left: 2px;
    width: 20%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-slide-menu {
    height: 50px;
    width: 700px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 4px;
    color: rgb(80, 80, 80);
}

.solution-slide-ct {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    /*background: #edf7ff;*/
}

.solution-slide-ct-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /*background: #edf7ff;*/
}

.solution-wrap {
    position: relative;
    width: 1000px;
    height: 500px;
}

.solution-txt {
    /*width: calc(100% - 1000px);*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: auto;
    /*background-color: #b7b7b7;*/
}

.solution-txt-column {
    margin-top: 20px;
    width: auto;
    height: 160px;
}

.solution-image-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    /*background-color: rgba(211, 228, 255, 0.2);*/
}

.solution-item-wrap {
    position: absolute;
    transform: scale(0.8);
}

.solution-item-wrap:hover .solution-item-ct-wrap {
    transform: scale(1.4);
    transition-duration: 1s;
}

.solution-item-ct-wrap {
    position: absolute;
    height: auto;
}

.outcome-title-ct {
    margin-left: 20px;
    width: calc(100% - 26px);
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
}

.solution-title-ct {
    height: 100%;
    width: 720px;
    background: transparent;
    display: flex;
    border-bottom: 3px solid rgb(60, 128, 212);
}

.solution-title-main {
    width: 36%;
    height: 60%;
    letter-spacing: 2px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    background: #ffffff;
}

.solution-title-des {
    width: 64%;
    height: 100%;
    letter-spacing: 4px;
    color: #909090;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.solution-detail-row-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.solution-detail-row-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 250px;
    height: 80%;
}

.solution-detail-item-img {
    width: 60px;
    height: 60px;
}

.solution-detail-row-item-txt {
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 1px;
    color: #3782C8;
}

.solution-detail-item-txt {
    margin-left: 40px;
    font-size: 16px;
    font-weight: 550;
    letter-spacing: 1px;
    color: #3782C8;
}

.solution-detail-column-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    border: 1px solid #c5c5c5;
}

.solution-detail-column-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 130px;
    border-bottom: 1px solid #c5c5c5;
    box-sizing: border-box;
}

.solution-detail-line {
    position: relative;
    top: -20px;
    width: 1px;
    height: 60px;
    background-color: #c5c5c5;
}

.title-main {
    height: 60%;
    width: 100%;
    letter-spacing: 2px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
}

.title-des {
    height: 40%;
    width: 100%;
    letter-spacing: 4px;
    color: #909090;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.title-hall {
    width: 108px;
    height: 28px;
    background: transparent;
    position: absolute;
    right: 0;
    border-bottom: 2px solid rgb(60, 128, 212);
    cursor: pointer;
    letter-spacing: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #8d8d8d;
}

.title-hall:hover {
    color: rgb(45, 45, 45);
    border-bottom: 3px solid rgb(0, 119, 255);
    height: 32px;
}

.outcome-ct {
    width: 100%;
    height: 840px;
    background: transparent;
}

.outcome-line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background: transparent;
}

.outcome-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0;
    width: 100%;
    height: 190px;
    /*background: #f3f3f3;*/
    color: #959595;
    box-sizing: border-box;
}

.outcome-item-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    height: 50px;
    width: 80%;
    font-size: 14px;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 2px;
    color: #6d6d6d;
    background: #ffffff;
}

.outcome-item-tags {
    width: 92%;
    height: 34px;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.expert-tag {
    font-size: 12px;
    color: #879db8;
    background: #e8f4ff;
    margin-right: 10px;
    letter-spacing: 1px;
    padding: 0 5px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.expert-area-tag {
    font-size: 12px;
    color: #71a790;
    background: #eefff0;
    margin-right: 10px;
    letter-spacing: 1px;
    padding: 0 10px 0 10px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.expert-job-tag {
    font-size: 12px;
    color: #a79871;
    background: #fffdee;
    margin-right: 10px;
    letter-spacing: 1px;
    padding: 0 10px 0 10px;
    height: 22px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.outcome-tag {
    font-size: 12px;
    color: #58a7ff;
    background: #e8f4ff;
    margin-right: 10px;
    letter-spacing: 1px;
    padding: 0 5px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    /*min-width: 92px;*/
}

.ellipsis {
    display: -webkit-box;
    /*-webkit-line-clamp: 4;*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 22px;
    text-align: center;
}

.outcome-item-des {
    display: -webkit-box;
    /*-webkit-line-clamp: 4;*/
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    word-break: break-all;

    width: 92%;
    height: 76px;
    font-size: 14px;
    letter-spacing: 1px;
    /*line-height: 20px;*/
    line-height: 26px;
    background: #ffffff;
}

.outcome-item-more {
    width: 92%;
    height: 50px;
    background: white;
    font-size: 12px;
    letter-spacing: 3px;
    display: flex;
    align-items: center;
    color: #0088ff;
}

.outcome-item-more:hover {
    font-weight: bold;
}

.outcome-item {
    width: 32%;
    height: 400px;
    background: white;
    max-width: 570px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.outcome-item:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.outcome-ot {
    height: 1100px;
    background: #edf7ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.demand-ot {
    /*height: 780px;*/
    min-height: 780px;
    background: #edf7ff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert-ot {
    display: flex;
    justify-content: center;
    align-items: center;
}

.policy-ot {
    height: 800px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.policy-ot-ad {
    height: 200px;
    /*background: #b9d1ea;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-ot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    height: auto;
    background: #ffffff;
}

/* news */
.news-ot {
    height: 720px;
    background: #eaf6fd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-content {
    margin: 0 auto;
    width: 100%;
    height: 62%;
}

.swiper-style {
    width: 100%;
    height: 100%;
}

.swiper-style-banner {
    height: 500px;
    width: 100%;
    min-width: 1200px;
    max-width: 2000px;
    position: absolute !important;
    z-index: -1;
}

.swiper-style-exp {
    width: 100%;
    height: 300px;
    background: white;
    display: flex;
    align-items: center;
}

.swiper-slide-style {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    height: 80%;
    cursor: pointer;
}

.news-img {
    width: 100%;
    height: 150px;
    max-width: 500px;
    overflow: hidden;
}

.news-img-transition {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-img-transition-enter {
    transform: translateY(0%) scale(1);
}

.news-img-transition-enter-active {
    transform: translateY(0%) scale(1.2);
    transition: all 400ms;
}

.news-img-transition-exit {
    transform: translateY(0%) scale(1.2);
}

.news-img-transition-exit-active {
    transform: translateY(0%) scale(1);
    transition: all 400ms;
}

.img-size {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-container {
    margin: 20px 0 0;
    width: 100%;
    height: 150px;
    max-width: 500px;
}

.news-title {
    font-size: 18px;
    font-weight: bolder;
}

.news-date {
    margin: 10px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.news-des {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    word-break: break-all;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.7);
}

.news-link {
    display: inline-block;
    margin: 20px 0 0;
    font-size: 14px;
    color: #2576d9;
}

.news-link-active {
    display: inline-block;
    margin: 20px 0 0;
    font-size: 14px;
    color: #0070ff;
    border-bottom: 1px solid #0070ff;
}

/* swiper */
/*.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    border-radius: 0;*/
/*}*/

.swiper-pagination-bullet-active {
    background: rgba(27, 138, 255, 0.8) !important;
    width: 20px !important;
    border-radius: 5px !important;
}

/* partner */
.partner-ot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;
    background: #ffffff;
}

.search-ot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background: #ffffff;
}

.partner-grid {
    margin: 0 auto;
    width: 95%;
    height: auto;
    background: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*justify-content: space-between;*/
}

.partner-line {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    width: 100%;
    height: 120px;
    background: transparent;
}

.partner-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    width: calc(100% / 8);
    height: 120px;
    cursor: pointer;
    color: gray;
    transition: all 100ms linear;
}

.partner-item:hover {
    transform: translateY(-20px);
    transition: all 100ms linear;
    color: black;
    /*font-weight: bold;*/
}

.partner-img-style {
    width: 90px;
    height: 90px;
}

.partner-name {
    font-size: 12px;
    line-height: 40px;
    /*letter-spacing: 2px;*/
}

.chart-service {
    width: 100%;
    height: 100%;
    display: flex;
}

.chart-sign {
    height: 42px;
    width: 60px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart-word {
    width: calc(100% - 60px);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    letter-spacing: 6px;
    /*font-weight: bold;*/
}

.contact-tool {
    width: 220px;
    height: 42px;
    bottom: 0;
    background: #6e86a7;
    right: 0;
    z-index: 999;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    cursor: pointer;
}

.contact-tool-h {
    width: 220px;
    height: 42px;
    background: #6e86a7;
    z-index: 999;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
}

.expand-contact {
    width: 260px;
    height: 300px;
    background: #ffffff;
    position: fixed;
    bottom: 10px;
    right: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    z-index: 999;
}

.close-contact {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #6e86a7;
    cursor: pointer;
}

.expend-header {
    width: 100%;
    height: 42px;
    display: flex;
}

.expend-ct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 42px);
    background: white;
}

.expend-ct-inter {
    height: 80%;
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-title {
    width: 100%;
    height: 20%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: bold;
    color: rgb(60, 128, 212);
}

.qr-ct {
    width: 100%;
    height: 80%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-img {
    height: 140px;
    width: 140px;
    background: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expend-back {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0;
    width: 90%;
    height: 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.expend-item {
    letter-spacing: 4px;
    font-size: 14px;
    color: rgb(60, 128, 212);
    height: 24%;
    width: 74%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.expend-item:hover {
    font-weight: bold;
}

.contact-tool:hover {
    background: rgb(33, 114, 213);
}

.contact-ct {
    width: 100%;
    min-width: 1200px;
    height: 42px;
    bottom: 0;
    background: transparent;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: -42px;
}

/* footer */
.footer-ot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    height: 280px;
    /*background: #f5f5f5;*/
    background: rgb(37, 37, 37);
    border-top: 2px solid rgba(0, 0, 0, 0.15);
}

.footer-wrap {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    height: 100%;
    background: transparent;
}

.footer-navigation {
    display: block;
    text-align: center;
    margin-bottom: 12px;
    text-decoration: none;
    font-weight: 500;
    /*color: #5183ce;*/
    color: white;
    background: transparent;
}

.footer-navigation-item {
    display: inline-block;
    margin: 16px 4px 0;
    font-size: 14px;
    letter-spacing: 6px;
    font-weight: bold;
}

/*.footer-navigation-item:hover {*/
/*    border-bottom: 1px solid #0073b1;*/
/*    cursor: pointer;*/
/*}*/

.footer-divider {
    margin: 15px auto;
    width: 25%;
    height: 0;
    min-width: 80px;
    border-top: 1px solid #000;
    opacity: 0.15;
}

.footer-link {
    margin: 20px 0;
    width: auto;
    height: auto;
}

.footer-link-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-link-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 12px;
    color: white;
}

.footer-link-item a {
    text-decoration: none;
}

.footer-link-icon {
    margin-right: 5px;
    font-size: 14px;
}

.footer-legal {
    width: auto;
    height: auto;
}

.footer-legal-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    float: left;
}

.footer-legal-item {
    display: block;
    float: left;
    margin-left: 24px;
}

.footer-legal-link {
    display: inline-block;
    padding: 4px 10px 3px;
}

.footer-base {
    margin: 20px auto 0;
    width: auto;
    height: auto;
}

.footer-base-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-qrs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    height: auto;
}

.footer-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 120px;
    height: 120px;
    background: white;
}

.footer-infos {
    display: flex;
    margin-left: 50px;
    width: auto;
    height: auto;
}

.footer-infos-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 10px;
    width: auto;
    height: 100%;
}

.footer-info {
    margin: 5px 0;
}

.footer-link-item-info {
    line-height: 16px;
    font-weight: 600;
    font-size: 13px;
    color: white;
    opacity: 0.8;
    cursor: pointer;
    /*color: rgba(0, 0, 0, 0.55);*/
}

.footer-link-item-info:hover {
    color: #00baff;
}

.footer-font {
    line-height: 16px;
    font-weight: 600;
    font-size: 13px;
    color: white;
    opacity: 0.8;
    /*color: rgba(0, 0, 0, 0.55);*/
}

ul, li, a {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

.ad-card-title {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-bottom: 2px solid rgba(227, 227, 227, 0.8);
    display: flex;
}

.ad-ct {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100% - 53px);
    background: transparent;
    box-sizing: border-box;
}

.maintenance-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* outcome ad */
.outcome-ad-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / 5);
}

.outcome-ad-line {
    width: 88%;
    height: 68px;
    background: transparent;
    display: flex;
}

.outcome-ad-line-sign {
    height: 68px;
    width: 68px;
    background: #cae3f3;
}

.outcome-ad-line-ct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    width: calc(100% - 78px);
    height: 68px;
    /*background: #e7e7e7;*/
    cursor: pointer;
}

.outcome-ad-line-name {
    overflow: hidden;
    font-size: 14px;
    font-weight: bolder;
    line-height: 22px;
    color: #797979;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.outcome-ad-line-ct:hover .outcome-ad-line-name {
    color: #224fd0;
}

.outcome-ad-line-com {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.outcome-ad-line-type {
    display: inline-block;
    text-align: center;
    width: auto;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
}

.outcome-ad-line-stage {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    width: auto;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #a79871;
}

.outcome-ad-line-areas {
    display: inline-block;
    text-align: center;
    width: auto;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: green;
    align-self: baseline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

/* demand ad */
.demand-ad-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / 5);
}

.demand-ad-line {
    display: flex;
    width: 88%;
    height: 68px;
    background: transparent;
    cursor: pointer;
}

.demand-ad-line-sign {
    height: 68px;
    width: 68px;
    background: #cae3f3;
}

.demand-ad-line-ct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 12px;
    width: calc(100% - 80px);
    height: 68px;
    background: transparent;
    /*border-bottom: 1px solid #cae3f3;*/
}

.demand-ad-line-name {
    overflow: hidden;
    height: 22px;
    font-size: 14px;
    font-weight: bolder;
    line-height: 22px;
    color: #797979;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.demand-ad-line:hover .demand-ad-line-name {
    color: #224fd0
}

.demand-ad-line-com {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.demand-ad-line-type {
    display: inline-block;
    text-align: center;
    width: auto;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #757575;
}

.demand-ad-line-price {
    margin-left: 8px;
    width: auto;
    height: 22px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 22px;
    color: red;
}

.demand-ad-line-areas {
    width: auto;
    height: 22px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 22px;
    color: green;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

/* expert ad */
.expert-ad-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / 6);
}

.expert-ad-line {
    display: flex;
    align-items: center;
    width: 88%;
    height: 100px;
    background: transparent;
    cursor: pointer;
}

.expert-ad-line-sign {
    overflow: hidden;
    width: 68px;
    height: 68px;
    border: 0;
    border-radius: 50%;
    background: #cae3f3;
}

.expert-ad-line-ct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 12px;
    width: calc(100% - 80px);
    height: 80px;
    background: transparent;
    /*border-bottom: 1px solid #cae3f3;*/
}

.expert-ad-line-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.expert-ad-line-name {
    height: 22px;
    font-size: 16px;
    font-weight: bolder;
    line-height: 22px;
    color: #797979;
    white-space: nowrap;
}

.expert-ad-line:hover .expert-ad-line-name {
    color: #224fd0
}

.expert-ad-line-hor {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    height: 22px;
    font-size: 12px;
    letter-spacing: 1px;
    text-indent: 1px;
    line-height: 22px;
    color: #879db8;
    background: #e8f4ff;
    /*border-radius: 4px;*/
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.expert-ad-line-target {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*padding: 0 10px;*/
    width: auto;
    height: 22px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 22px;
    color: #58b295;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    /*background: #eefff0;*/
    /*border-radius: 4px;*/
    /*align-self: baseline;*/
}

.expert-ad-line-des {
    overflow: hidden;
    font-size: 12px;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

/* solution ad */
.solution-ad-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.solution-ad-line {
    /*margin-top: calc(35px / 2);*/
    width: 88%;
    height: 68px;
    background: transparent;
    display: flex;
}

.solution-ad-line-sign {
    height: 68px;
    width: 68px;
    /*background: #cae3f3;*/
    border: 1px solid #ededed;
    box-sizing: border-box;
}

.solution-ad-line-ct {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    width: calc(100% - 78px);
    height: 68px;
    /*background: #e7e7e7;*/
    cursor: pointer;
}

.solution-ad-line-name {
    overflow: hidden;
    font-size: 14px;
    font-weight: bolder;
    line-height: 22px;
    color: #797979;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.solution-ad-line-ct:hover .solution-ad-line-name {
    color: #224fd0;
}

.solution-ad-line-des {
    overflow: hidden;
    width: auto;
    height: 44px;
    font-size: 12px;
    line-height: 22px;
    color: #4d4d4d;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

/* policy ad */
.policy-ad-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% / 6);
}

.policy-ad-line {
    display: flex;
    width: 88%;
    height: 68px;
    background: transparent;
}

.policy-ad-line-sign {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    background: #cae3f3;
    font-size: 16px;
    font-weight: bolder;
    color: #000000;
}

.policy-ad-line-ct {
    padding-left: 10px;
    width: calc(100% - 68px);
    height: 67px;
    background: transparent;
    border-bottom: 1px solid #cae3f3;
    cursor: pointer;
}

.policy-ad-line-name {
    height: 44px;
    overflow: hidden;
    font-size: 14px;
    font-weight: bolder;
    line-height: 22px;
    color: #797979;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.policy-ad-line:hover .policy-ad-line-name {
    color: #224fd0
}

.policy-ad-line-date {
    font-size: 12px;
    line-height: 22px;
    color: rgb(100, 100, 100);
}

.ad-card-title-des {
    width: calc(100% - 70px);
    height: calc(100% + 2px);
    background: transparent;
    display: flex;
    padding-left: 20px;
    font-size: 14px;
    color: #6d6d6d;
    letter-spacing: 1px;
    font-weight: bold;
}

.ad-card-title-ct {
    background: transparent;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #4176e1;
}

.ad-card-title-tag {
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #b1b1b1;
    cursor: pointer;
}

.ad-card-title-tag:hover {
    color: #4176e1;
}

.wechat-scan-wrap {
    z-index: 10;
    position: relative;
    width: 100%;
    height: 300px
    /*background: #71a790;*/
}

.wx-qr {
    z-index: 10;
    position: relative;
    text-align: center;
    padding-top: 20px;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
}

.ali-plugin-wrap {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 936px;
    min-width: 1200px;
    overflow: hidden;
}

.qr-iframe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 102px;
    height: 104px;
    border: 0;
    background-color: transparent;
}

a {
    text-decoration: none;
}