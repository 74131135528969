.policy-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 1200px;
}

.policy-filter-ct {
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    background: transparent;
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.policy-filter-outer {
    width: 100%;
    background: transparent;
    z-index: 3;
}

.policy-data-item {
    width: 98%;
    height: 120px;
    background: #ffffff;
    margin: 10px 0 20px 0;
    display: flex;
    align-items: center;
}

.policy-ct-data {
    min-height: 1254px;
    width: calc(100% - 340px);
    background: transparent;
}

.policy-page-content {
    position: relative;
    width: 80%;
    min-width: 1200px;
    max-width: 1800px;
    min-height: 1254px;
    background: transparent;
    display: flex;
    justify-content: space-between;
}

.policy-item-square {
    height: 70px;
    width: 70px;
    background: #ffffff;
    display: flex;
    align-items: center;
}

.save-policy {
    position: absolute;
    width: 70px;
    font-size: 12px;
    background: #f3f3f3;
    letter-spacing: 1px;
    height: 22px;
    display: flex;
    text-indent: 1px;
    align-items: center;
    justify-content: center;
    color: #5c81a7;
    transform: translate(-26px, -32px);
}

.policy-item-ct {
    width: calc(100% - 140px);
    height: 68px;
    background: #ffffff;
    display: flex;
}

.policy-item-title {
    cursor: pointer;
    width: 60%;
    min-width: 420px;
    padding-left: 20px;
    padding-right: 60px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #5f5f5f;
    line-height: 26px;
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d7e5f1;
}

.policy-item-title:hover {
    color: #0066ff;
}

.policy-item-info {
    width: 200px;
    height: 100%;
    background: #d2d2d2;
    border-bottom: 1px solid #d7e5f1;
}

.policy-item-info-line {
    width: 200px;
    height: 50%;
    background: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}

.policy-dp-word{
    color: rgb(92,120,170);
    font-weight: bold;
    cursor: pointer;
}

.policy-dp-word:hover {
    color: #0070ff;
}

.policy-bk {
    width: 100%;
    height: 240px;
    background: #2066b5;
    display: flex;
    z-index: -1;
    justify-content: center;
}

.policy-banner-img {
    position: absolute;
    height: 240px;
    max-width: 1700px;
    min-width: 1200px;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    z-index: 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.policy-ct-ad {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 340px;
    height: 1230px;
    background: transparent;
}

.policy-ct-ad-one {
    width: 100%;
    height: 500px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

.policy-ct-ad-two {
    margin-top: 30px;
    width: 100%;
    height: 720px;
    border: 1px solid rgba(227, 227, 227, 0.8);
    background: transparent;
}

h1 {
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: left;
    font-size: 26px;
    font-weight: bolder;
    font-family: Apple, sans-serif;
    letter-spacing: 1px;
    border-bottom: 1px solid #e5e5e5;
}

.time {
    margin-top: 20px;
    font-size: 16px;
}

.article {
    margin-top: 40px;
    width: 100%;
    height: auto;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 30px;
}

.article p {
    margin-bottom: 30px;
}

.article-img {
    margin: 0 auto;
    width: 600px;
    /*height: 400px;*/
}